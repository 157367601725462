import { Trans } from '@lingui/macro';
import EditableCard from 'components/base/form/editablecard/editablecard';
import { FieldError } from 'components/base/form/errornotice/errornotice';
import {
  SimpleSelectField,
  SimpleSelectFieldProps,
} from 'components/base/form/simpleselect/simpleselectfield';
import Loading from 'components/base/loading/loading';
import {
  FastField,
  FormikHelpers,
  FormikErrors,
  Field,
  FormikProps,
} from 'formik';
import React, { useEffect, useState } from 'react';
import { MaintObsPointAllMenus, SectionProps } from '../maintobspoint.types';
import { validateNumber, isNotNull } from 'util/validation';
import { showErrorsInFormik } from 'util/backendapi/error-formik';
import { SitesMenuAutoLoading } from 'components/modules/sitesmenu/sitesmenu';
import { Model, Enum } from 'util/backendapi/models/api.interfaces';
import { getExpectedFields } from 'util/backendapi/error';
import {
  YesNoStringToggleField,
  YesNoToggleField,
} from 'components/base/form/toggle-field/ToggleField';
import { SimpleSelectOption } from 'components/base/form/simpleselect/simpleselect';
import { rangeInclusive } from 'util/misc';
import FormChangeEffect from 'components/base/form/formchangeeffect/formchangeeffect';
import {
  formatTimeofdayForBackendapi,
  formatTimeofdayForDisplay,
  formatDatetimeForDisplay,
  getCurrentDatetime,
} from 'util/dates';
import { DatetimeField } from 'components/base/form/datefield/datefield';
import Button from 'components/base/button/button';
import lodashGet from 'lodash/get';
import lodashSet from 'lodash/set';
import { AlertInfo } from 'components/base/alert/alert';
import { DMSLink } from 'components/base/link/DMSLink';
import { createSelector } from 'reselect';

interface ObservationPointTimeDependentFieldValue {
  value: string;
  start_datetime: string;
  change_reason: string;
  editing: boolean;
}

interface MyFormValues {
  name: string;
  classification: number | null;
  civil_feature: number;
  civil_feature__area_code: string;
  site: number;
  area__code: string;
  grid_reference: number;
  grid_y: string;
  grid_x: string;
  reading_lat: string;
  reading_lng: string;
  instrument_type: number;
  extensometer_length: string;
  instrument_comment: string;
  performance_characteristics: string;
  plotAsHistogram: boolean;
  histogram_day_cutoff_time: string;
  isEditingSerialNumber: boolean;
  serialNumber: { value: string; start_datetime: string } | null;
  instrumentReliability: ObservationPointTimeDependentFieldValue;
  maintenanceRequired: ObservationPointTimeDependentFieldValue;
}
type MyFormikErrors = FormikErrors<MyFormValues>;
type Props = SectionProps & {
  onFetchAreaCodeCivilFeatures: (
    areaCode: string
  ) => Promise<Model.CivilFeature[]>;
  observationPointCode: string;
  menuOptions: Pick<
    MaintObsPointAllMenus,
    | 'classification'
    | 'area__code'
    | 'grid_reference'
    | 'instrument_type'
    | 'reliability'
  >;
};

function CivilFeatureField(props: {
  formik: FormikProps<MyFormValues>;
  fetchAreaCodeCivilFeatures: (
    areaCode: string
  ) => Promise<Model.CivilFeature[]>;
}) {
  const { formik, fetchAreaCodeCivilFeatures } = props;

  const [areaCivilFeatureOptions, setAreaCivilFeatureOptions] = useState<
    SimpleSelectOption<number>[]
  >([]);

  const setFieldTouched = formik.setFieldTouched;
  const areaCode = formik.values.area__code;

  useEffect(() => {
    fetchAreaCodeCivilFeatures(areaCode).then((areaCivilFeatures) => {
      const options = [{ label: '-', value: 0 }].concat(
        areaCivilFeatures.map((cf) => ({ label: cf.name, value: cf.id }))
      );
      setAreaCivilFeatureOptions(options);
      setFieldTouched('civil_feature');
    });
  }, [fetchAreaCodeCivilFeatures, setFieldTouched, areaCode]);

  return (
    <>
      <SimpleSelectField<number, false>
        name="civil_feature"
        options={areaCivilFeatureOptions}
        isMulti={false}
        onChange={() => {
          formik.setFieldValue(
            'civil_feature__area_code',
            formik.values.area__code
          );
        }}
      />
      <FieldError name="civil_feature" />
    </>
  );
}

export default class ObsPointGeneralSection extends React.Component<Props> {
  // Options for the "histogram cutoff hour" menu
  private histogramDayCutoffOptions: SimpleSelectOption<string>[] =
    rangeInclusive(0, 23).map((hour) => {
      const timeOfDay = { hour };
      return {
        value: formatTimeofdayForBackendapi(timeOfDay),
        label: formatTimeofdayForDisplay(timeOfDay),
      };
    });

  private findTimeZone = createSelector(
    (formValues: MyFormValues, _props: Props) => formValues.area__code,
    (_formValues: MyFormValues, props: Props) => props.menuOptions.area__code,
    function (areaCode, areaCodeOptions) {
      return areaCodeOptions?.find((a) => a.value === areaCode)?.timeZone;
    }
  );

  shouldDisable = () => {
    return (
      this.props.isDisabled || this.props.isLoading || this.props.isSubmitting
    );
  };

  validate(values: MyFormValues) {
    let errors: MyFormikErrors = {};
    const numberFields: Array<keyof MyFormValues> = [
      'grid_y',
      'grid_x',
      'reading_lat',
      'reading_lng',
      'extensometer_length',
    ];

    const requiredFields: Array<keyof MyFormValues> = [
      'classification',
      'site',
      'area__code',
      'instrument_type',
    ];

    numberFields.forEach(function (fieldName) {
      if (values[fieldName] !== '' && !validateNumber(values[fieldName])) {
        errors[fieldName] = (<Trans>This value must be a number</Trans>) as any;
      }
    });

    requiredFields.forEach(function (fieldName) {
      if (!values[fieldName]) {
        errors[fieldName] = (<Trans>This field is required</Trans>) as any;
      }
    });

    if (values.isEditingSerialNumber) {
      if (!values.serialNumber?.start_datetime) {
        lodashSet(
          errors,
          'serialNumber.start_datetime',
          (<Trans>This field is required</Trans>) as any
        );
      }
    }

    if (values.instrumentReliability.editing) {
      if (!values.instrumentReliability.value) {
        lodashSet(
          errors,
          'instrumentReliability.value',
          (<Trans>This field is required</Trans>) as any
        );
      }

      if (!values.instrumentReliability.start_datetime) {
        lodashSet(
          errors,
          'instrumentReliability.start_datetime',
          (<Trans>This field is required</Trans>) as any
        );
      }
    }

    if (values.maintenanceRequired.editing) {
      if (!values.maintenanceRequired.value) {
        lodashSet(
          errors,
          'maintenanceRequired.value',
          (<Trans>This field is required</Trans>) as any
        );
      }

      if (!values.maintenanceRequired.start_datetime) {
        lodashSet(
          errors,
          'maintenanceRequired.start_datetime',
          (<Trans>This field is required</Trans>) as any
        );
      }

      if (
        values.maintenanceRequired.value === 'true' &&
        !values.maintenanceRequired.change_reason
      ) {
        lodashSet(
          errors,
          'maintenanceRequired.change_reason',
          (<Trans>This field is required</Trans>) as any
        );
      }
    }

    if (
      values.civil_feature &&
      values.area__code !== values.civil_feature__area_code
    ) {
      lodashSet(
        errors,
        'civil_feature',
        (
          <Trans>
            Civil Feature must be in the current Area. Please reselect from
            list.
          </Trans>
        ) as any
      );
    }

    return errors;
  }

  handleSubmit = async (
    { plotAsHistogram, ...values }: MyFormValues,
    formik: FormikHelpers<MyFormValues>
  ) => {
    try {
      const valuesToSubmit: any = {
        ...values,
        civil_feature: values.civil_feature ? values.civil_feature : null,
        grid_y: values.grid_y === '' ? null : values.grid_y,
        grid_x: values.grid_x === '' ? null : values.grid_x,
        reading_lat: values.reading_lat === '' ? null : values.reading_lat,
        reading_lng: values.reading_lng === '' ? null : values.reading_lng,
        extensometer_length:
          values.extensometer_length === '' ? null : values.extensometer_length,
        histogram_day_cutoff_time:
          values.histogram_day_cutoff_time === ''
            ? null
            : values.histogram_day_cutoff_time,
        grid_reference: values.grid_reference ? values.grid_reference : null,
      };
      delete valuesToSubmit.serialNumber;
      delete valuesToSubmit.instrumentReliability;
      delete valuesToSubmit.maintenanceRequired;
      if (values.isEditingSerialNumber && values.serialNumber) {
        valuesToSubmit.observation_point_time_dependent_fields = {};
        valuesToSubmit.observation_point_time_dependent_fields[
          Enum.ObservationPoint_TIME_DEPENDENT_FIELD_NAME.serial_number
        ] = [
          {
            value: values.serialNumber.value,
            start_datetime: values.serialNumber.start_datetime,
          },
        ];
      }
      if (values.instrumentReliability.editing) {
        if (!valuesToSubmit.observation_point_time_dependent_fields) {
          valuesToSubmit.observation_point_time_dependent_fields = {};
        }
        valuesToSubmit.observation_point_time_dependent_fields[
          Enum.ObservationPoint_TIME_DEPENDENT_FIELD_NAME.instrument_reliability
        ] = [
          {
            value: values.instrumentReliability.value,
            change_reason: values.instrumentReliability.change_reason,
            start_datetime: values.instrumentReliability.start_datetime,
          },
        ];
      }
      if (values.maintenanceRequired.editing) {
        if (!valuesToSubmit.observation_point_time_dependent_fields) {
          valuesToSubmit.observation_point_time_dependent_fields = {};
        }
        valuesToSubmit.observation_point_time_dependent_fields[
          Enum.ObservationPoint_TIME_DEPENDENT_FIELD_NAME.maintenance_required
        ] = [
          {
            value: values.maintenanceRequired.value,
            change_reason: values.maintenanceRequired.change_reason,
            start_datetime: values.maintenanceRequired.start_datetime,
          },
        ];
      }
      await this.props.onSubmit(valuesToSubmit);
    } catch (e) {
      showErrorsInFormik(formik, e, getExpectedFields(values));
      formik.setSubmitting(false);
    }
  };

  render() {
    const { observationPoint: op } = this.props;
    if (!op) {
      return <Loading />;
    }

    const serialNumberTimeDependentFields: Model.ObservationPointTimeDependentField[] =
      op.observation_point_time_dependent_fields.serial_number;
    const reliabilityTimeDependentFields: Model.ObservationPointTimeDependentField[] =
      op.observation_point_time_dependent_fields.instrument_reliability;
    const maintenanceRequiredTimeDependentFields: Model.ObservationPointTimeDependentField[] =
      op.observation_point_time_dependent_fields.maintenance_required;

    const initialValues: MyFormValues = {
      name: op.name,
      civil_feature: op.civil_feature?.id ?? 0,
      // Used to validate civil feature belongs to the same area
      civil_feature__area_code:
        op.area.id === op.civil_feature?.area ? op.area.code : '',
      classification: op.classification && op.classification.id,
      performance_characteristics: op.performance_characteristics,
      site: op.site.id,
      area__code: op.area.code,
      grid_reference: op.grid_reference ? op.grid_reference.id : 0,
      grid_y: op.grid_y === null ? '' : op.grid_y,
      grid_x: op.grid_x === null ? '' : op.grid_x,
      reading_lat: op.reading_lat === null ? '' : op.reading_lat,
      reading_lng: op.reading_lng === null ? '' : op.reading_lng,
      instrument_type: op.instrument_type.id,
      extensometer_length:
        op.extensometer_length === null ? '' : op.extensometer_length,
      instrument_comment: op.instrument_comment,
      plotAsHistogram: Boolean(op.histogram_day_cutoff_time),
      histogram_day_cutoff_time: op.histogram_day_cutoff_time ?? '',
      isEditingSerialNumber: false,
      serialNumber: serialNumberTimeDependentFields.length
        ? {
            // The backend orders fields by most recent first
            value: serialNumberTimeDependentFields[0].value,
            start_datetime: serialNumberTimeDependentFields[0].start_datetime,
          }
        : { value: '', start_datetime: '' },
      instrumentReliability: reliabilityTimeDependentFields.length
        ? {
            value: reliabilityTimeDependentFields[0].value,
            start_datetime: reliabilityTimeDependentFields[0].start_datetime,
            change_reason: '',
            editing: false,
          }
        : { value: '', start_datetime: '', change_reason: '', editing: false },
      maintenanceRequired: maintenanceRequiredTimeDependentFields.length
        ? {
            value: maintenanceRequiredTimeDependentFields[0].value,
            start_datetime:
              maintenanceRequiredTimeDependentFields[0].start_datetime,
            change_reason: '',
            editing: false,
          }
        : {
            value: 'false',
            start_datetime: '',
            change_reason: '',
            editing: false,
          },
    };

    const instrumentReliabilityOptions = this.props.menuOptions.reliability.map(
      (reliability) => ({
        label: reliability.label,
        // Use the first part of the label which is the reliability code
        value: reliability.label.split(' ')[0],
      })
    );

    return (
      <EditableCard<MyFormValues>
        name="general"
        header={<Trans>General</Trans>}
        shouldDisable={this.shouldDisable()}
        hasEditPermission={this.props.hasEditPermission}
        isEditMode={this.props.isEditing}
        startEditing={this.props.startEditing}
        stopEditing={this.props.stopEditing}
        onSubmit={this.handleSubmit}
        initialValues={initialValues}
        validate={this.validate}
        // TODO: optimize the rendering speed of this a bit. Maybe memoize it?
        render={({ CardSectionComponent, formik }) => {
          // Find the timeZone of the currently selected area.
          const timeZone = this.findTimeZone(formik.values, this.props);
          return (
            <>
              {formik.status}
              <CardSectionComponent
                name="name"
                header={<Trans>Name</Trans>}
                fields={[
                  {
                    name: 'observationPointCode',
                    label: <Trans>Observation point</Trans>,
                    content: (
                      <p className="non-editable-value">
                        {this.props.observationPointCode}
                      </p>
                    ),
                  },
                  this.stringField(<Trans>Name</Trans>, 'name'),
                  this.selectField(
                    <Trans>Classification</Trans>,
                    'classification',
                    op.classification && op.classification.code
                  ),
                  this.textAreaField(
                    <Trans>Performance characteristics</Trans>,
                    'performance_characteristics'
                  ),
                ]}
              />
              <CardSectionComponent
                name="location"
                header={<Trans>Location</Trans>}
                fields={[
                  {
                    name: 'site',
                    label: <Trans>Site</Trans>,
                    content: this.props.isEditing ? (
                      <>
                        <SitesMenuAutoLoading
                          id="general-site"
                          name="site"
                          initialSiteLabel={op.site.code}
                          selectedAreaCode={formik.values.area__code}
                          isDisabled={
                            this.shouldDisable() || !formik.values.area__code
                          }
                        />
                        <FieldError name="site" />
                      </>
                    ) : (
                      op.site.code
                    ),
                  },
                  this.selectField(
                    <Trans>Area</Trans>,
                    'area__code',
                    op.area.name
                  ),
                  this.selectField(
                    <Trans>Grid reference</Trans>,
                    'grid_reference',
                    op.grid_reference && op.grid_reference.code
                  ),
                  this.unitOfMeasurementField(
                    <Trans>Northing/Latitude</Trans>,
                    'grid_y',
                    null
                  ),
                  this.unitOfMeasurementField(
                    <Trans>Easting/Longitude</Trans>,
                    'grid_x',
                    null
                  ),
                  this.displayWGS84Coordinate(
                    <Trans>Latitude</Trans>,
                    'wgs84_coordinates',
                    'lat'
                  ),
                  this.displayWGS84Coordinate(
                    <Trans>Longitude</Trans>,
                    'wgs84_coordinates',
                    'lng'
                  ),
                  {
                    name: 'civil_feature',
                    label: <Trans>Civil feature</Trans>,
                    content: this.props.isEditing ? (
                      <CivilFeatureField
                        formik={formik}
                        fetchAreaCodeCivilFeatures={
                          this.props.onFetchAreaCodeCivilFeatures
                        }
                      />
                    ) : (
                      op.civil_feature?.name
                    ),
                  },
                ].filter(isNotNull)}
              />
              <CardSectionComponent
                name="reading_location"
                header={<Trans>Reading location</Trans>}
                fields={[
                  <AlertInfo className="alert-condensed" key="instructions">
                    <Trans>
                      Reading location should be specified when readings are
                      taken from a different location to the instrument (e.g.
                      piezo gallery).
                    </Trans>
                  </AlertInfo>,
                  this.unitOfMeasurementField(
                    <Trans>Latitude</Trans>,
                    'reading_lat',
                    null
                  ),
                  this.unitOfMeasurementField(
                    <Trans>Longitude</Trans>,
                    'reading_lng',
                    null
                  ),
                ].filter(isNotNull)}
              />
              <CardSectionComponent
                name="instrument"
                header={<Trans>Instrument</Trans>}
                fields={[
                  this.selectField(
                    <Trans>Instrument type</Trans>,
                    'instrument_type',
                    op.instrument_type.code
                  ),
                  {
                    name: 'serialNumberValues',
                    columns: [
                      {
                        name: 'serialNumberValue',
                        label: <Trans>Serial number</Trans>,
                        content: this.props.isEditing ? (
                          <Field
                            type="text"
                            name="serialNumber.value"
                            disabled={!formik.values.isEditingSerialNumber}
                          />
                        ) : formik.values.serialNumber ? (
                          <span>{formik.values.serialNumber.value}</span>
                        ) : null,
                      },
                      {
                        name: 'serialNumberDatetime',
                        label: <Trans>Start</Trans>,
                        content: this.props.isEditing ? (
                          <>
                            {formik.values.isEditingSerialNumber ? (
                              <>
                                <DatetimeField
                                  name="serialNumber.start_datetime"
                                  timeZone={timeZone}
                                />
                                <FieldError name="serialNumber.start_datetime" />
                              </>
                            ) : (
                              <>
                                <DatetimeField
                                  name="serialNumber.start_datetime"
                                  disabled={true}
                                  timeZone={timeZone}
                                />
                                <Button
                                  onClick={() => {
                                    const currentDateTime =
                                      getCurrentDatetime();

                                    let serialNumberValue = lodashGet(
                                      formik.values,
                                      'serialNumber.value'
                                    );

                                    formik.setFieldValue(
                                      'serialNumber',
                                      {
                                        start_datetime: currentDateTime,
                                        value: serialNumberValue,
                                      },
                                      false
                                    );

                                    formik.setFieldValue(
                                      'isEditingSerialNumber',
                                      true
                                    );
                                  }}
                                >
                                  <Trans>Edit</Trans>
                                </Button>
                              </>
                            )}
                          </>
                        ) : (
                          formik.values.serialNumber && (
                            <span>
                              {formatDatetimeForDisplay(
                                formik.values.serialNumber?.start_datetime,
                                timeZone
                              )}
                            </span>
                          )
                        ),
                      },
                      this.props.isEditing
                        ? null
                        : {
                            name: 'serialNumberHistoryLink',
                            content: (
                              <DMSLink
                                to={`/observation-point/${this.props.observationPointCode}/time-dependent-fields/serial_number`}
                              >
                                <Trans>View history</Trans>
                              </DMSLink>
                            ),
                          },
                    ].filter(isNotNull),
                  },
                  this.timeDependentField(
                    formik,
                    'instrumentReliability',
                    'instrument_reliability',
                    <Trans>Instrument reliability</Trans>,
                    this.props.isEditing ? (
                      <>
                        <SimpleSelectField<string, false>
                          name="instrumentReliability.value"
                          isDisabled={
                            !formik.values.instrumentReliability.editing
                          }
                          options={instrumentReliabilityOptions}
                        />
                        <FieldError name="instrumentReliability.value" />
                      </>
                    ) : (
                      <span>
                        {
                          instrumentReliabilityOptions.find(
                            (r) =>
                              r.value ===
                              formik.values.instrumentReliability.value
                          )?.label
                        }
                      </span>
                    ),
                    formik.values.instrumentReliability,
                    timeZone
                  ),
                  this.timeDependentFieldChangeReason(
                    'instrumentReliability',
                    <Trans>Reason for instrument reliability change</Trans>,
                    formik.values.instrumentReliability.editing
                  ),
                  this.timeDependentField(
                    formik,
                    'maintenanceRequired',
                    'maintenance_required',
                    <Trans>Maintenance required</Trans>,
                    this.props.isEditing ? (
                      <>
                        <YesNoStringToggleField
                          name="maintenanceRequired.value"
                          disabled={!formik.values.maintenanceRequired.editing}
                        />
                        <FieldError name="maintenanceRequired.value" />
                      </>
                    ) : formik.values.maintenanceRequired.value === 'true' ? (
                      <Trans>Yes</Trans>
                    ) : (
                      <Trans>No</Trans>
                    ),
                    formik.values.maintenanceRequired,
                    timeZone
                  ),
                  this.timeDependentFieldChangeReason(
                    'maintenanceRequired',
                    <Trans>Reason maintenance is required</Trans>,
                    formik.values.maintenanceRequired.editing &&
                      formik.values.maintenanceRequired.value === 'true'
                  ),
                  this.unitOfMeasurementField(
                    <Trans>Extensometer length</Trans>,
                    'extensometer_length',
                    <Trans>m</Trans>
                  ),
                  this.textAreaField(
                    <Trans>Instrument comment</Trans>,
                    'instrument_comment'
                  ),
                  {
                    name: 'plotAsHistogram',
                    label: <Trans>Plot as histogram - daily</Trans>,
                    content: this.props.isEditing ? (
                      <YesNoToggleField name="plotAsHistogram" />
                    ) : this.props.observationPoint
                        ?.histogram_day_cutoff_time ? (
                      <Trans>Yes</Trans>
                    ) : (
                      <Trans>No</Trans>
                    ),
                  },
                  {
                    name: 'histogram_day_cutoff_time',
                    label: <Trans>Histogram day end time</Trans>,
                    content: this.props.isEditing ? (
                      <>
                        <SimpleSelectField<string, false>
                          name="histogram_day_cutoff_time"
                          isDisabled={!formik.values.plotAsHistogram}
                          options={this.histogramDayCutoffOptions}
                          isMulti={false}
                        />
                        <FieldError name="histogram_day_cutoff_time" />
                        <FormChangeEffect
                          onChange={(prevProps) => {
                            const prev = prevProps.values.plotAsHistogram;
                            const cur = formik.values.plotAsHistogram;
                            if (prev && !cur) {
                              formik.setFieldValue(
                                'histogram_day_cutoff_time',
                                ''
                              );
                            } else if (!prev && cur) {
                              formik.setFieldValue(
                                'histogram_day_cutoff_time',
                                this.props.observationPoint
                                  ?.histogram_day_cutoff_time ?? '08:00:00'
                              );
                            }
                          }}
                        />
                      </>
                    ) : (
                      formatTimeofdayForDisplay(
                        this.props.observationPoint?.histogram_day_cutoff_time
                      )
                    ),
                  },
                ].filter(isNotNull)}
              />
            </>
          );
        }}
      />
    );
  }

  /**
   * Helper function for a string field
   * TODO: Refactor this into a reusable component?
   *
   * @memberof MaintainObservationPointView
   */
  stringField = (
    label: React.ReactNode,
    prop: keyof Model.ObservationPointDecorated,
    fieldProps = {}
  ) => {
    return {
      name: prop,
      label,
      content: this.props.isEditing ? (
        <>
          <FastField
            data-testid={`general-${prop}`}
            disabled={this.shouldDisable()}
            type="text"
            name={prop}
            {...fieldProps}
          />
          <FieldError name={prop} />
        </>
      ) : (
        this.props.observationPoint && this.props.observationPoint[prop]
      ),
    };
  };

  timeDependentField = (
    formik: FormikProps<MyFormValues>,
    name: string,
    backendFieldName: string,
    label: React.ReactNode,
    field: React.ReactNode,
    value: ObservationPointTimeDependentFieldValue,
    timeZone: string | undefined
  ) => {
    const valueFieldName = `${name}.value`;
    const datetimeFieldName = `${name}.start_datetime`;
    return {
      name: name,
      columns: [
        {
          name: `${name}Value`,
          label: label,
          content: field,
        },
        {
          name: `${name}Datetime`,
          label: <Trans>Start</Trans>,
          content: this.props.isEditing ? (
            <>
              {value.editing ? (
                <>
                  <DatetimeField name={datetimeFieldName} timeZone={timeZone} />
                  <FieldError name={datetimeFieldName} />
                </>
              ) : (
                <>
                  <DatetimeField
                    name={datetimeFieldName}
                    disabled={true}
                    timeZone={timeZone}
                  />
                  <Button
                    onClick={() => {
                      const currentDateTime = getCurrentDatetime();

                      let reliabilityValue = lodashGet(
                        formik.values,
                        valueFieldName
                      );

                      formik.setFieldValue(
                        name,
                        {
                          start_datetime: currentDateTime,
                          value: reliabilityValue,
                        },
                        false
                      );

                      formik.setFieldValue(`${name}.editing`, true);
                    }}
                  >
                    <Trans>Edit</Trans>
                  </Button>
                </>
              )}
            </>
          ) : (
            formik.values.instrumentReliability && (
              <span>
                {formatDatetimeForDisplay(value.start_datetime, timeZone)}
              </span>
            )
          ),
        },
        this.props.isEditing
          ? null
          : {
              name: `${name}HistoryLink`,
              content: (
                <DMSLink
                  to={`/observation-point/${this.props.observationPointCode}/time-dependent-fields/${backendFieldName}`}
                >
                  <Trans>View history</Trans>
                </DMSLink>
              ),
            },
      ].filter(isNotNull),
    };
  };

  timeDependentFieldChangeReason = (
    name: string,
    label: React.ReactNode,
    isEditingTimeDependentField: boolean
  ) => {
    const changeReasonFieldName = `${name}.change_reason`;
    return isEditingTimeDependentField
      ? {
          name: `${name}ChangeReason`,
          label: label,
          content: (
            <>
              <FastField
                disabled={this.shouldDisable()}
                type="text"
                name={changeReasonFieldName}
              />
              <FieldError name={changeReasonFieldName} />
            </>
          ),
        }
      : null;
  };

  /**
   * Helper function for a field that displays a number with a unit of
   * measurement.
   *
   * @memberof ObsPointGeneralSection
   */
  unitOfMeasurementField = (
    label: React.ReactNode,
    prop: keyof Model.ObservationPointDecorated,
    unitOfMeasurement: React.ReactNode | null,
    fieldProps = {}
  ) => {
    return {
      name: prop,
      label,
      content: this.props.isEditing ? (
        <div className="input-unit-wrapper">
          <FastField
            data-testid={`general-${prop}`}
            disabled={this.shouldDisable()}
            type="text"
            name={prop}
            className="text-input-with-unit"
            {...fieldProps}
          />
          {unitOfMeasurement ? (
            <span className="input-unit">{unitOfMeasurement}</span>
          ) : null}
          <FieldError name={prop} />
        </div>
      ) : (
        this.props.observationPoint && this.props.observationPoint[prop]
      ),
    };
  };

  /**
   * Helper function for a textarea field
   * TODO: Refactor this into a reusable component?
   *
   * @memberof MaintainObservationPointView
   */
  textAreaField = (
    label: React.ReactNode,
    prop: keyof Model.ObservationPointDecorated,
    fieldProps = {}
  ) => {
    return {
      name: prop,
      label,
      content: this.props.isEditing ? (
        <>
          <FastField
            data-testid={`general-${prop}`}
            disabled={this.shouldDisable()}
            component="textarea"
            maxLength={5000}
            name={prop}
            {...fieldProps}
          />
          <FieldError name={prop} />
        </>
      ) : (
        <span className="text-with-linebreaks">
          {this.props.observationPoint && this.props.observationPoint[prop]}
        </span>
      ),
    };
  };

  /**
   * Helper function for a field that displays a selection menu
   *
   * @memberof MaintainObservationPointView
   */
  selectField = (
    label: React.ReactNode,
    prop: keyof Props['menuOptions'],
    nonEditContent: React.ReactNode,
    fieldProps: Partial<SimpleSelectFieldProps<any>> = {}
  ) => {
    let content;

    if (this.props.isEditing) {
      let options = this.props.menuOptions[prop];
      content = options ? (
        <>
          <SimpleSelectField<any>
            name={prop}
            data-testid={`general-${prop}`}
            isDisabled={this.shouldDisable()}
            {...fieldProps}
            options={options}
          />
          <FieldError name={prop} />
        </>
      ) : (
        <Loading />
      );
    } else {
      content = nonEditContent;
    }

    return { name: prop, label, content };
  };

  /**
   * Helper function to display coordinates
   */
  displayWGS84Coordinate = (
    label: React.ReactNode,
    prop: 'wgs84_coordinates' | 'reading_wgs84_coordinates',
    coordinate_field: keyof Model.LatLong
  ) => {
    const wgs84_coordinates = this.props.observationPoint?.[prop];

    if (!wgs84_coordinates || this.props.isEditing) return null;

    // Don't display for LL grid reference
    if (this.props.observationPoint?.grid_reference?.code === 'LL') return null;

    const content = (
      <div x-ms-format-detection="none">
        {wgs84_coordinates[coordinate_field]}
      </div>
    );

    const name = prop + '-' + coordinate_field;

    return {
      name,
      label,
      content,
    };
  };
}
