import React, { useCallback, useMemo, useState } from 'react';
import { Trans, t } from '@lingui/macro';
import { Media } from 'util/backendapi/types/Model';
import ModalContent from 'components/base/modal/modalcontent';
import { Media_FILE_TYPE } from 'util/backendapi/types/Enum';
import { formatDatetimeForDisplay } from 'util/dates';
import { Form, Formik, FormikHelpers } from 'formik';
import { FormItem, FormSection } from 'components/base/form/FormItem';
import { deleteApi, patchApi, patchApiFormData } from 'util/backendapi/fetch';
import {
  FormConfirmation,
  useFormConfirmation,
} from 'components/base/confirmation/FormConfirmation';
import { ButtonPrimary } from 'components/base/button/button';
import ActionBlock from 'components/base/actionblock/actionblock';
import { useIsMounted } from 'util/hooks';
import { showErrorsInFormik } from 'util/backendapi/error-formik';
import { getExpectedFields } from 'util/backendapi/error';
import ButtonShowModal from 'components/base/modal/buttonshowmodal';
import { InlineTextEdit } from 'components/base/inline/InlineTextEdit';
import { FieldError } from 'components/base/form/errornotice/errornotice';
import { FileField } from 'components/base/form/file-field/FileField';
import FormChangeEffect from 'components/base/form/formchangeeffect/formchangeeffect';
import { Icon } from 'components/base/icon/icon';
import './PreviewMediaModal.scss';

export interface PreviewMediaModalProps {
  media: Media;
  observationPointCode: string;
  timeZone: string;
  canReplaceMedia: boolean;
  canEditMediaCaption: boolean;
  hideModal: () => void;
  onAfterSubmit: () => void;
  onAfterEditMediaCaption?: (content: string) => void;
}

type FormValues = {
  id: number;
  new_file: File | null;
  new_file_name: string;
};

interface PreviewMediaButtonProps {
  media: Media;
  observationPointCode: string;
  timeZone: string;
  canReplaceMedia?: boolean;
  canEditMediaCaption?: boolean;
  onAfterSubmit: () => void;
  onAfterEditMediaCaption?: (content: string) => void;
}

const PREVIEW_MEDIA_WIDTH = 128;

function mediaThumbnail(media: Media) {
  if (media.file_type === Media_FILE_TYPE.IMAGE) {
    return (
      <img
        title={media.description}
        alt=""
        width={PREVIEW_MEDIA_WIDTH}
        src={media.file}
      />
    );
  }

  if (media.file_type === Media_FILE_TYPE.VIDEO) {
    return (
      <video
        title={media.description}
        width={PREVIEW_MEDIA_WIDTH}
        src={media.file}
      />
    );
  }

  if (media.file_type === Media_FILE_TYPE.AUDIO) {
    return (
      <Icon
        type="icon-audio-file"
        className="preview-audio-thumbnail-icon"
        title={media.description}
      />
    );
  }

  return null;
}

function mediaPreview(media: Media) {
  if (media.file_type === Media_FILE_TYPE.IMAGE) {
    return <img title={media.description} src={media.file} alt="" />;
  }

  if (media.file_type === Media_FILE_TYPE.VIDEO) {
    return <video title={media.description} src={media.file} controls />;
  }

  if (media.file_type === Media_FILE_TYPE.AUDIO) {
    return <audio title={media.description} src={media.file} controls />;
  }

  return null;
}

export function PreviewMediaButton(props: PreviewMediaButtonProps) {
  const {
    media,
    observationPointCode,
    timeZone,
    canEditMediaCaption,
    canReplaceMedia,
    onAfterSubmit,
    onAfterEditMediaCaption,
  } = props;

  const thumbnail = mediaThumbnail(media);

  if (!thumbnail) return null;

  return (
    <ButtonShowModal
      name="mediaPreviewModal"
      modalContent={(modalProps) => (
        <PreviewMediaModal
          {...modalProps}
          media={media}
          observationPointCode={observationPointCode}
          timeZone={timeZone}
          onAfterSubmit={onAfterSubmit}
          canReplaceMedia={canReplaceMedia ?? false}
          canEditMediaCaption={canEditMediaCaption ?? false}
          onAfterEditMediaCaption={onAfterEditMediaCaption}
        />
      )}
    >
      {thumbnail}
    </ButtonShowModal>
  );
}

export function PreviewMediaModal(props: PreviewMediaModalProps) {
  const {
    media,
    observationPointCode,
    timeZone,
    hideModal,
    onAfterSubmit,
    onAfterEditMediaCaption,
    canReplaceMedia,
    canEditMediaCaption,
  } = props;
  const [askForConfirmation, handleConfirmation] = useFormConfirmation();
  const isMounted = useIsMounted();

  const [isEditingCaption, setEditingCaption] = useState<boolean>(false);

  const preview = mediaPreview(media);

  const initialValues: FormValues = useMemo(
    () => ({
      id: media.id,
      new_file: null,
      new_file_name: '',
    }),
    [media]
  );

  const handleSubmit = useCallback(
    async (values: FormValues, formik: FormikHelpers<FormValues>) => {
      if (!values.new_file) return;

      const formData = new FormData();
      formData.append('file', values.new_file!, values.new_file!.name);

      try {
        await patchApiFormData(`/media/${values.id}/`, formData);
        if (!isMounted()) {
          return;
        }
        onAfterSubmit();
        hideModal();
      } catch (e) {
        if (!isMounted()) {
          return;
        }
        formik.setSubmitting(false);
        showErrorsInFormik(formik, e, getExpectedFields(initialValues));
      }
    },
    [onAfterSubmit, hideModal, isMounted, initialValues]
  );

  const deleteMedia = useCallback(
    async (mediaId: number, formik: FormikHelpers<FormValues>) => {
      const result = await askForConfirmation();
      if (!result) {
        formik.setSubmitting(false);
        return;
      }

      try {
        await deleteApi(`/media/${mediaId}/`);
        if (!isMounted()) {
          return;
        }
        onAfterSubmit();
        hideModal();
      } catch (e) {
        if (!isMounted()) {
          return;
        }
        formik.setSubmitting(false);
        showErrorsInFormik(formik, e, getExpectedFields(initialValues));
      }
    },
    [onAfterSubmit, hideModal, isMounted, askForConfirmation, initialValues]
  );

  return (
    <ModalContent
      className="preview-media-modal"
      header={<Trans>Preview media</Trans>}
    >
      <Formik<FormValues> initialValues={initialValues} onSubmit={handleSubmit}>
        {(formik) => (
          <Form>
            <FormConfirmation
              onResolve={handleConfirmation}
              okBtnText={<Trans>Yes, delete</Trans>}
              content={() => {
                return (
                  <Trans>
                    Are you sure you want to delete this media item? This action
                    is not reversible.
                  </Trans>
                );
              }}
            />
            {preview}
            <span>
              <a href={media.file}>
                <Trans>Download</Trans>
              </a>
            </span>
            {formik.status}
            <FormSection>
              {canReplaceMedia ? (
                <FormItem label={<Trans>Upload a new file</Trans>}>
                  <FileField
                    name="new_file_name"
                    fileFieldName="new_file"
                    disabled={formik.isSubmitting}
                  />
                  <FieldError name="new_file_name" />
                </FormItem>
              ) : null}
              <FormChangeEffect
                onChange={(prevFormikState) => {
                  if (
                    prevFormikState.values.new_file_name !==
                    formik.values.new_file_name
                  ) {
                    formik.submitForm();
                  }
                }}
              />
              <FormItem label={<Trans>Caption</Trans>}>
                {canEditMediaCaption ? (
                  <InlineTextEdit
                    title={t`Edit caption`}
                    content={media.description}
                    isEditing={isEditingCaption}
                    setIsEditing={(editing) => setEditingCaption(editing)}
                    onSubmit={async (content) => {
                      await patchApi(`/media/${media.id}/`, {
                        description: content,
                      });
                    }}
                    onAfterSubmit={onAfterEditMediaCaption}
                  />
                ) : (
                  media.description
                )}
              </FormItem>
              <FormItem label={<Trans>Observation point</Trans>}>
                {observationPointCode}
              </FormItem>
              <FormItem label={<Trans>Media datetime</Trans>}>
                {formatDatetimeForDisplay(media.uploaded_datetime, timeZone)}
              </FormItem>
              <FormItem label={<Trans>Uploaded by</Trans>}>
                {media.uploaded_by.profile.name} ({media.uploaded_by.username})
              </FormItem>
            </FormSection>
            <ActionBlock>
              <ButtonPrimary
                data-testid="delete-media-btn"
                disabled={formik.isSubmitting}
                iconType="icon-circle-minus"
                onClick={() => deleteMedia(formik.values.id, formik)}
              >
                <Trans>Delete</Trans>
              </ButtonPrimary>
            </ActionBlock>
          </Form>
        )}
      </Formik>
    </ModalContent>
  );
}
