import React, { useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Trans } from '@lingui/macro';
import PageStandard from 'components/modules/pagestandard/pagestandard';
import './StoredPlot.scss';
import { Icon } from 'components/base/icon/icon';
import ActionBlock from 'components/base/actionblock/actionblock';
import ButtonShowModal from 'components/base/modal/buttonshowmodal';
import { ExportPanel } from 'components/modules/exportpanel/exportpanel';
import { StoredPlot } from 'components/plots/StoredPlot';
import { Enum } from 'util/backendapi/models/api.interfaces';
import { formatDateForDisplay, convertDatetimeToDate } from 'util/dates';
import { BackButton } from 'components/base/back-button/BackButton';
import { DMSLink } from 'components/base/link/DMSLink';
import { useStoredPlotState } from 'hooks/use-stored-plot-state';
import {
  fetchStoredPlotReadings,
  unmountStoredPlotReadings,
} from 'ducks/plot/stored-plot';
import { useDispatch } from 'react-redux';
import { unmountStoredPlotDetails } from 'ducks/stored-plot/detail';
import { HasPermission } from 'components/logic/has-permission/HasPermission';

export const StoredPlotScreen = () => {
  const dispatch = useDispatch();
  const { plotName } = useParams<{ plotName: string }>();

  const {
    record: storedPlot,
    loading: isLoadingStoredPlot,
    errorMessage: storedPlotErrorMessage,
  } = useStoredPlotState(plotName);

  useEffect(() => {
    if (storedPlot) {
      dispatch(fetchStoredPlotReadings(storedPlot));
    }
    return () => {
      if (storedPlot) {
        dispatch(unmountStoredPlotReadings(storedPlot.plot_type));
        dispatch(unmountStoredPlotDetails());
      }
    };
  }, [dispatch, storedPlot]);

  const header =
    storedPlot?.plot_type === Enum.PlotType.TIME_SERIES ||
    storedPlot?.plot_type === Enum.PlotType.SCATTER ? (
      <Trans>Stored Plot</Trans>
    ) : (
      <Trans>Spatial Plot</Trans>
    );

  const storedPlotTitle = storedPlot ? (
    storedPlot.plot_type === Enum.PlotType.SPATIAL_PLAN ||
    storedPlot.plot_type === Enum.PlotType.SPATIAL_CROSS_SECTION ? (
      <Trans>
        {storedPlot.area.name} {storedPlot.title} as at{' '}
        {formatDateForDisplay(
          convertDatetimeToDate(
            storedPlot.default_reading_datetime || new Date(),
            storedPlot.area.time_zone.name
          )
        )}
        <br />
        {storedPlot.description}
      </Trans>
    ) : storedPlot.plot_type === Enum.PlotType.SPATIAL_WANDER ? (
      <Trans>
        {storedPlot.area.name} {storedPlot.title} as at{' '}
        {formatDateForDisplay(
          convertDatetimeToDate(
            storedPlot.time_periods[storedPlot.time_periods.length - 1] ||
              new Date(),
            storedPlot.area.time_zone.name
          )
        )}
        <br />
        {storedPlot.description}
      </Trans>
    ) : (
      storedPlot.description
    )
  ) : null;

  // A ref to an HTML element that individual stored plot types can use to
  // add additional controls to to the top of the screen next to "settings"
  // and "export"
  const buttonsPortal = useRef<HTMLElement>(null);

  return (
    <PageStandard
      name="storedplot"
      header={header}
      subHeader={storedPlot ? storedPlot.name : ''}
    >
      <div className="page-content-header-with-back-button-wrapper">
        <BackButton defaultBackUrl="/stored-plots" />
        <div className="page-content-header columns-fluid">
          <div className="plot-page-header-info">
            <h2 data-testid="stored-plot-description">{storedPlotTitle}</h2>
          </div>
          {storedPlot ? (
            <ActionBlock className="text-right">
              <span ref={buttonsPortal} />
              <HasPermission check="can_create_stored_plots">
                <DMSLink
                  to={`/stored-plots/${storedPlot.name}/edit`}
                  className="btn"
                  shortcut="GOTO_SETTINGS"
                >
                  <span>
                    <Trans>Settings</Trans>
                  </span>
                  <Icon type="icon-cog" />
                </DMSLink>
              </HasPermission>

              <ButtonShowModal
                name="export"
                modalContent={() => (
                  <ExportPanel
                    title={<Trans>Export stored plot</Trans>}
                    description={
                      <Trans>
                        The plot will be exported with the same settings as
                        displayed on screen.
                      </Trans>
                    }
                    canExportPdf={true}
                    getExportUrl={() => ({
                      path:
                        storedPlot.plot_type === Enum.PlotType.SPATIAL_PLAN ||
                        storedPlot.plot_type ===
                          Enum.PlotType.SPATIAL_CROSS_SECTION ||
                        storedPlot.plot_type === Enum.PlotType.SPATIAL_WANDER
                          ? `/export/pdf/spatial-stored-plots/${storedPlot.id}/`
                          : `/export/pdf/stored-plots/${storedPlot.id}/`,
                      queryParams: new URLSearchParams(),
                    })}
                  />
                )}
                iconType="icon-export"
              >
                <Trans>Export</Trans>
              </ButtonShowModal>
            </ActionBlock>
          ) : null}
        </div>
      </div>
      <StoredPlot
        isLoading={isLoadingStoredPlot}
        errorMessage={storedPlotErrorMessage}
        storedPlot={storedPlot}
        buttonsPortal={buttonsPortal}
      />
    </PageStandard>
  );
};
