import React from 'react';
import PageStandard from 'components/modules/pagestandard/pagestandard';
import { Trans } from '@lingui/macro';
import {
  PolymorphicStoredPlotWithArea,
  StoredTimeSeriesPlotWithArea,
  StoredScatterPlotWithArea,
  StoredSurveyLevellingPlotWithArea,
  StoredSpatialPlanPlotWithArea,
  PolymorphicStoredSpatialPlotWithArea,
  StoredSpatialCrossSectionPlotWithArea,
  StoredSpatialWanderPlotWithArea,
} from 'ducks/stored-plot/detail';
import Loading from 'components/base/loading/loading';
import { Model } from 'util/backendapi/models/api.interfaces';
import { AlertWarning } from 'components/base/alert/alert';
import './StoredPlotEdit.scss';
import { PlotType } from 'util/backendapi/types/Enum';

import { StoredTimeSeriesPlotForm } from './StoredTimeSeriesPlotForm';
import { StoredScatterPlotForm } from './StoredScatterPlotForm';
import { StoredSurveyLevellingPlotForm } from './StoredSurveyLevellingPlotForm';
import { StoredSpatialPlanPlotForm } from './StoredSpatialPlanPlotForm';
import { NewStoredPlotDefaults } from './stored-plot-edit-utils';
import { StoredSpatialCrossSectionPlotForm } from './StoredSpatialCrossSectionPlotForm';
import { StoredSpatialWanderPlotForm } from './StoredSpatialWanderPlotForm';
import { StoredPlotSaveAsSettings } from './StoredPlotSaveAsModal';
import { DMSHotKey } from 'main/DMSHotKey';

export interface StoredPlotEditViewProps {
  storedPlot:
    | PolymorphicStoredPlotWithArea
    | PolymorphicStoredSpatialPlotWithArea
    | null;
  areaOptions: { value: number; label: string; timeZone: string }[];
  isLoading: boolean;
  isNewPlot: boolean;
  newPlotDefaults: NewStoredPlotDefaults | null;
  errorMessage: string;
  onSubmit: (
    values:
      | Model.PolymorphicStoredPlot_POST
      | Model.PolymorphicStoredSpatialPlot_POST,
    saveAs: StoredPlotSaveAsSettings | null
  ) => Promise<any>;
  onCancel: () => void;
}

export const StoredPlotEditView = (props: StoredPlotEditViewProps) => {
  const {
    storedPlot,
    isLoading,
    isNewPlot,
    newPlotDefaults,
    errorMessage,
    areaOptions,
    onSubmit,
    onCancel,
  } = props;

  const plotType = isNewPlot
    ? newPlotDefaults?.plot_type
    : storedPlot?.plot_type;

  return (
    <PageStandard
      name="edit-stored-plot"
      header={
        isNewPlot ? (
          <Trans>Create Stored Plot</Trans>
        ) : (
          <Trans>Edit Stored Plot</Trans>
        )
      }
      subHeader={storedPlot ? storedPlot.name : null}
    >
      <DMSHotKey shortcut="GO_BACK" onPress={onCancel} />
      {isLoading ? (
        <Loading />
      ) : errorMessage ? (
        <AlertWarning>{errorMessage}</AlertWarning>
      ) : !storedPlot && !isNewPlot ? (
        <AlertWarning>
          <Trans>Stored plot not found</Trans>
        </AlertWarning>
      ) : plotType === PlotType.TIME_SERIES ? (
        <StoredTimeSeriesPlotForm
          storedPlot={storedPlot as StoredTimeSeriesPlotWithArea}
          newPlotDefaults={props.newPlotDefaults}
          areaOptions={areaOptions}
          onSubmit={onSubmit}
          onCancel={onCancel}
        />
      ) : plotType === PlotType.SCATTER ? (
        <StoredScatterPlotForm
          storedPlot={storedPlot as StoredScatterPlotWithArea}
          newPlotDefaults={props.newPlotDefaults}
          areaOptions={areaOptions}
          onSubmit={onSubmit}
          onCancel={onCancel}
        />
      ) : plotType === PlotType.SURVEY_LEVELLING ? (
        <StoredSurveyLevellingPlotForm
          storedPlot={storedPlot as StoredSurveyLevellingPlotWithArea}
          newPlotDefaults={props.newPlotDefaults}
          areaOptions={areaOptions}
          onSubmit={onSubmit}
          onCancel={onCancel}
        />
      ) : plotType === PlotType.SPATIAL_PLAN ? (
        <StoredSpatialPlanPlotForm
          storedPlot={storedPlot as StoredSpatialPlanPlotWithArea}
          newPlotDefaults={props.newPlotDefaults}
          areaOptions={areaOptions}
          onSubmit={onSubmit}
          onCancel={onCancel}
        />
      ) : plotType === PlotType.SPATIAL_CROSS_SECTION ? (
        <StoredSpatialCrossSectionPlotForm
          storedPlot={storedPlot as StoredSpatialCrossSectionPlotWithArea}
          newPlotDefaults={props.newPlotDefaults}
          areaOptions={areaOptions}
          onSubmit={onSubmit}
          onCancel={onCancel}
        />
      ) : plotType === PlotType.SPATIAL_WANDER ? (
        <StoredSpatialWanderPlotForm
          storedPlot={storedPlot as StoredSpatialWanderPlotWithArea}
          newPlotDefaults={props.newPlotDefaults}
          areaOptions={areaOptions}
          onSubmit={onSubmit}
          onCancel={onCancel}
        />
      ) : (
        <AlertWarning>
          <Trans>Unsupported plot type</Trans>
        </AlertWarning>
      )}
    </PageStandard>
  );
};
