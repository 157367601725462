import moment from 'moment-timezone';
import orderBy from 'lodash/orderBy';
import { createSelector } from 'reselect';
import { t } from '@lingui/macro';
import { getApi } from 'util/backendapi/fetch';
import {
  formatDatetimeForStorage,
  formatDatetimeForBackendUrl,
  formatIntervalForComputer,
} from 'util/dates';
import { compareAlarmParameterLevels } from 'util/backendapi/models/alarmparameter';
import { Model, Filter, Enum } from 'util/backendapi/models/api.interfaces';
import {
  TSPlotAlarmParamSeries,
  TSPlotReading,
  TIMESERIES_BUCKETS,
  ScatterPlotSettings,
  PolymorphicPlotSettings,
  PlotSettingsAxis,
  QuickPlotAxisSide,
  ObservationPointItemUrlCode,
  QuickPlotSettings,
  PlotSettings,
  TSPlotZoomParams,
  buildStoredPlotKey,
  buildReadingSeriesKey,
} from 'components/plots/timeseriesplot.types';
import { StandardThunk, DuckActions } from 'main/store';
import { errorToString } from 'util/backendapi/error';
import {
  ActionTypes as CommentActionTypes,
  CommentsPanelListAction,
} from 'ducks/comments/panel/list';
import { setWithoutMutating } from 'util/misc';
import {
  StoredTimeSeriesPlotWithArea,
  StoredScatterPlotWithArea,
} from '../stored-plot/detail';
import { isNotNull } from 'util/validation';

/**
 * This is a duck for rendering time series plots and scatter plots, both
 * stored and "quick"
 *
 * This includes these things (as identified in the UI:)
 *
 * - Quick plot (quick time series plot)
 * - Scatter plot (quick scatter plot)
 * - Stored quick plot (stored plot item)
 * - Stored scatter plot (stored plot item)
 */

export const ActionTypes = {
  FETCH_READINGS_START: 'dms/plot/scatterTimeSeries/FETCH_READINGS_START',
  FETCH_READINGS_RESPONSE: 'dms/plot/scatterTimeSeries/FETCH_READINGS_RESPONSE',
  FETCH_READINGS_ERROR: 'dms/plot/scatterTimeSeries/FETCH_READINGS_ERROR',
  RESOLVE_PLOT_SETTINGS_START:
    'dms/plot/scatterTimeSeries/RESOLVE_PLOT_SETTINGS_START',
  RESOLVE_PLOT_SETTINGS_RESPONSE:
    'dms/plot/scatterTimeSeries/RESOLVE_PLOT_SETTINGS_RESPONSE',
  RESOLVE_PLOT_SETTINGS_ERROR:
    'dms/plot/scatterTimeSeries/RESOLVE_PLOT_SETTINGS_ERROR',
  RESOLVE_PLOT_SETTINGS_UPDATE:
    'dms/plot/scatterTimeSeries/RESOLVE_PLOT_SETTINGS_UPDATE',
  SET_STORED_PLOT_ZOOM: 'dms/plot/scatterTimeSeries/SET_STORED_PLOT_ZOOM',
  UNMOUNT_PLOT_PAGE: 'dms/plot/scatterTimeSeries/UNMOUNT_PLOT_PAGE',
} as const;

export const ActionCreators = {
  UNMOUNT_PLOT_PAGE: () => {
    return {
      type: ActionTypes.UNMOUNT_PLOT_PAGE,
    };
  },
  FETCH_READINGS_START: (key: string, zoomKey: string) => {
    return {
      type: ActionTypes.FETCH_READINGS_START,
      key,
      zoomKey,
    };
  },
  FETCH_READINGS_RESPONSE: (
    key: string,
    zoomKey: string,
    plotReadings: TSPlotReading[]
  ) => {
    return {
      type: ActionTypes.FETCH_READINGS_RESPONSE,
      key,
      zoomKey,
      payload: plotReadings,
    };
  },
  FETCH_READINGS_ERROR: (
    key: string,
    zoomKey: string,
    errorMessage: string
  ) => {
    return {
      type: ActionTypes.FETCH_READINGS_ERROR,
      key,
      zoomKey,
      payload: errorMessage,
    };
  },
  RESOLVE_PLOT_SETTINGS_UPDATE: (
    plotKey: string,
    settings: PolymorphicPlotSettings
  ) => {
    return {
      type: ActionTypes.RESOLVE_PLOT_SETTINGS_UPDATE,
      plotKey,
      payload: settings,
    };
  },
  RESOLVE_PLOT_SETTINGS_START: (plotKey: string, metadataHash: string) => {
    return {
      type: ActionTypes.RESOLVE_PLOT_SETTINGS_START,
      plotKey,
      metadataHash,
    };
  },
  RESOLVE_PLOT_SETTINGS_RESPONSE: (
    plotKey: string,
    metadataHash: string,
    settings: PolymorphicPlotSettings
  ) => {
    return {
      type: ActionTypes.RESOLVE_PLOT_SETTINGS_RESPONSE,
      plotKey,
      metadataHash,
      payload: settings,
    };
  },
  RESOLVE_PLOT_SETTINGS_ERROR: (
    plotKey: string,
    metadataHash: string,
    errorMessage: string
  ) => {
    return {
      type: ActionTypes.RESOLVE_PLOT_SETTINGS_ERROR,
      plotKey,
      metadataHash,
      error: true,
      payload: errorMessage,
    };
  },
  SET_STORED_PLOT_ZOOM: (
    storedPlotId: number,
    storedPlotItemId: number,
    zoom: TSPlotZoomParams | null
  ) => {
    return {
      type: ActionTypes.SET_STORED_PLOT_ZOOM,
      storedPlotId,
      storedPlotItemId,
      payload: zoom,
    };
  },
};

export const unmountPlotPage = ActionCreators.UNMOUNT_PLOT_PAGE;
export const setStoredPlotZoom = ActionCreators.SET_STORED_PLOT_ZOOM;

export type ScatterTimeSeriesPlotAction = DuckActions<
  typeof ActionTypes,
  typeof ActionCreators
>;

export interface ReadingSeriesState {
  loading: boolean;
  errorMessage: string;
  readings: TSPlotReading[] | null;
  // Indicates the zoom level the data for this series was fetched at.
  zoomKey: string;
}

export interface SinglePlotState<
  T extends PolymorphicPlotSettings = PolymorphicPlotSettings
> {
  metadataHash: null | string;
  resolved: null | T;
  isLoading: boolean;
  errorMessage: string;
  zoom: null | TSPlotZoomParams;
}

export interface MultiPlotsState {
  quickPlot: SinglePlotState<QuickPlotSettings>;
  quickScatterPlot: SinglePlotState<ScatterPlotSettings>;
  [plotKey: string]: SinglePlotState;
}

export interface ScatterTimeSeriesPlotState {
  plots: MultiPlotsState;
  readingsSeries: {
    [key: string]: ReadingSeriesState;
  };
}

export function scatterTimeSeriesPlotInitialState(): ScatterTimeSeriesPlotState {
  return {
    readingsSeries: {},
    plots: multiPlotsInitialState(),
  };
}

export const readingsInitialState = (): Readonly<ReadingSeriesState> => ({
  loading: false,
  errorMessage: '',
  readings: null,
  zoomKey: '',
});

export const multiPlotsInitialState = (): Readonly<MultiPlotsState> => ({
  quickPlot: {
    metadataHash: null,
    resolved: null,
    isLoading: false,
    errorMessage: '',
    zoom: null,
  },
  quickScatterPlot: {
    metadataHash: null,
    resolved: null,
    isLoading: false,
    errorMessage: '',
    zoom: null,
  },
});

function readingsReducer(
  state: ReadingSeriesState = readingsInitialState(),
  action: ScatterTimeSeriesPlotAction
): ReadingSeriesState {
  switch (action.type) {
    case ActionTypes.FETCH_READINGS_START:
      return {
        ...state,
        loading: true,
        zoomKey: action.zoomKey,
      };

    case ActionTypes.FETCH_READINGS_RESPONSE:
      if (state.zoomKey === action.zoomKey) {
        return {
          ...state,
          loading: false,
          errorMessage: '',
          readings: action.payload,
        };
      } else {
        return state;
      }

    case ActionTypes.FETCH_READINGS_ERROR:
      if (state.zoomKey === action.zoomKey) {
        return {
          ...state,
          loading: false,
          errorMessage: action.payload,
          readings: null,
        };
      } else {
        return state;
      }

    default:
      return state;
  }
}

function multiPlotsReducer(
  state: MultiPlotsState = multiPlotsInitialState(),
  action: ScatterTimeSeriesPlotAction
): MultiPlotsState {
  switch (action.type) {
    case ActionTypes.RESOLVE_PLOT_SETTINGS_START:
      return {
        ...state,
        [action.plotKey]: {
          ...(state[action.plotKey] || {}),
          isLoading: true,
          errorMessage: '',
          metadataHash: action.metadataHash,
          resolved: null,
          zoom: null,
        },
      };

    case ActionTypes.RESOLVE_PLOT_SETTINGS_UPDATE:
      if (!state[action.plotKey] || !state[action.plotKey].resolved) {
        // eslint-disable-next-line no-console
        console.error(
          'Unexpected incomplete redux state for RESOLVE_PLOT_SETTINGS_UPDATE'
        );
        return state;
      }
      return {
        ...state,
        [action.plotKey]: {
          ...state[action.plotKey],
          resolved: action.payload,
        },
      };

    case ActionTypes.RESOLVE_PLOT_SETTINGS_RESPONSE:
      return {
        ...state,
        [action.plotKey]: {
          ...state[action.plotKey],
          isLoading: false,
          resolved: action.payload,
        },
      };

    case ActionTypes.RESOLVE_PLOT_SETTINGS_ERROR:
      return {
        ...state,
        [action.plotKey]: {
          ...state[action.plotKey],
          isLoading: false,
          errorMessage: action.payload,
        },
      };

    case ActionTypes.SET_STORED_PLOT_ZOOM: {
      const { storedPlotId, storedPlotItemId, payload: zoom } = action;
      const keys = Object.keys(state);

      const nextState: {
        [plotKey: string]: SinglePlotState;
      } = {};

      keys.forEach((key) => {
        if (String(key).startsWith(`StoredPlot:${storedPlotId}`)) {
          if (
            // Zoom is being reset; clear for all plots in stored plot
            zoom === null ||
            // OR it's the specific plot we zoomed on, set X & Y of zoom
            key === buildStoredPlotKey(storedPlotId, storedPlotItemId)
          ) {
            nextState[key] = {
              ...state[key],
              zoom,
            };
          } else {
            // It's another plot in the stored plot; set the X axis but not
            // the Y axis.
            nextState[key] = {
              ...state[key],
              zoom: {
                ...state[key].zoom,
                minDatetime: zoom.minDatetime,
                maxDatetime: zoom.maxDatetime,
              },
            };
          }
        }
      });

      return {
        ...state,
        ...nextState,
      };
    }

    default:
      return state;
  }
}

export function scatterTimeSeriesPlotReducer(
  state = scatterTimeSeriesPlotInitialState(),
  action: ScatterTimeSeriesPlotAction | CommentsPanelListAction
): ScatterTimeSeriesPlotState {
  switch (action.type) {
    case ActionTypes.UNMOUNT_PLOT_PAGE:
      return scatterTimeSeriesPlotInitialState();
    case ActionTypes.FETCH_READINGS_START:
    case ActionTypes.FETCH_READINGS_RESPONSE:
    case ActionTypes.FETCH_READINGS_ERROR: {
      const subState = state.readingsSeries[action.key];
      const newSubState = readingsReducer(subState, action);
      if (subState === newSubState) {
        return state;
      }
      return {
        ...state,
        readingsSeries: {
          ...state.readingsSeries,
          [action.key]: newSubState,
        },
      };
    }

    case ActionTypes.RESOLVE_PLOT_SETTINGS_START:
    case ActionTypes.RESOLVE_PLOT_SETTINGS_UPDATE:
    case ActionTypes.RESOLVE_PLOT_SETTINGS_RESPONSE:
    case ActionTypes.RESOLVE_PLOT_SETTINGS_ERROR:
    case ActionTypes.SET_STORED_PLOT_ZOOM:
      return {
        ...state,
        plots: multiPlotsReducer(state.plots, action),
      };

    case CommentActionTypes.ADD_COMMENT_RESPONSE:
      // When a user clicks on an non-bucketed reading in the time series plot,
      // then clicks the comment count, and enters a new comment in the comments
      // panel, and this takes the reading from 0 comments to 1 comment, we
      // need to update the plot to display the dot for that new reading.
      if (
        action.payload.resourcetype === Enum.Comment_RESOURCE_TYPE.reading ||
        action.payload.resourcetype ===
          Enum.Comment_RESOURCE_TYPE.readingInspector
      ) {
        const comment: Model.ReadingComment | Model.ReadingInspectorComment =
          action.payload;

        // See whether the reading with the comment is in one of our plotted series
        // (It may be in multiple series, if they're plotting multiple items
        // from the same series!)
        let newState = state;
        Object.entries(state.readingsSeries).forEach(
          ([seriesKey, serie]) =>
            serie.readings &&
            serie.readings.some((reading, readingIdx) => {
              if (reading.reading_id === comment.reading) {
                newState = setWithoutMutating(
                  newState,
                  // Passing the path as an array, because the series key may
                  // contain "." characters now, and lodash will split it on
                  // those if we pass the whole thing as one string.
                  [
                    'readingsSeries',
                    seriesKey,
                    'readings',
                    readingIdx,
                    comment.comment_type === Enum.Comment_TYPE.analysis
                      ? 'hasAnalysisCommentIndicator'
                      : 'hasInspectorCommentIndicator',
                  ],
                  true
                );
                // The reading can only occur once in each series. So return true
                // to stop searching in this serie.
                return true;
              } else {
                return false;
              }
            })
        );
        return newState;
      } else {
        return state;
      }

    default:
      return state;
  }
}

/**
 * Helper function to flatten & convert the array of buckets from the downsampled
 * plotting endpoint, into an array of readings as we use for plotting.
 */
export function flattenReadingsBuckets({
  buckets,
  gaps,
}: Model.ReadingsPlotDownsampled): TSPlotReading[] {
  return orderBy(
    buckets
      .flatMap((bucket) => {
        // We nest a copy of the bucket into each of its readings. To avoid
        // self-referencing data structures, the nested copy of the bucket has
        // no "readings" property.
        const { readings, ...bucketWithoutReadings } = bucket;

        return bucket.readings.map((reading): TSPlotReading => {
          const isTopReading =
            reading.value === parseFloat(bucket.max_value as string);
          return {
            x: new Date(reading.reading_datetime),
            y: reading.value,
            reading_id: reading.reading_id,
            bucket:
              bucket.full_readings_count > 1 ? bucketWithoutReadings : null,
            hasAnalysisCommentIndicator:
              isTopReading && bucket.analysis_comment_count > 0,
            hasInspectorCommentIndicator:
              isTopReading && bucket.inspector_comment_count > 0,
            hasMediaIndicator: isTopReading && bucket.media_count > 0,
          };
        });
      })
      .concat(
        gaps.map(
          // Add a null-value reading entry to plot each gap comment.
          (g): TSPlotReading => ({
            x: new Date(g),
            y: null,
            reading_id: 0,
          })
        )
      ),
    [(item) => item.x.toISOString()]
  );
}

/**
 * Helper function to take an array of readings representing a Standard Rain
 * Gauge, and group them together into a histogram of daily rainfall.
 *
 * It would be cleaner to do this closer to the plot rendering, but for
 * performance reasons it's better to do it once, immediately after fetching.
 *
 * @param observationPoint
 * @param param1
 */
export function sumRainfallHistogram(
  observationPoint: Model.ObservationPointDecorated,
  readings: Model.ReadingsPlotItem[]
): TSPlotReading[] {
  const cutoffTime = observationPoint.histogram_day_cutoff_time;
  if (!cutoffTime) {
    throw new Error(
      'Attempting to plot obs point as a rainfall histogram, but obs point has no histogram day end setting'
    );
  }

  if (readings.length === 0) {
    return [];
  }

  const timeZone = observationPoint.time_zone.name;

  // Readings are assumed to be sorted already when they reach this function.
  const minDatetime = moment.tz(readings[0].reading_datetime, timeZone);
  const maxDatetime = moment.tz(
    readings[readings.length - 1].reading_datetime,
    timeZone
  );
  const readingsLength = readings.length;

  // Find the start of the first rainfall day. (It'll be the first occurrence
  // of the cutoff time, before the time of the first reading)
  const binStart = minDatetime
    .clone()
    .startOf('day')
    .add(moment.duration(cutoffTime));
  if (binStart.isSameOrAfter(minDatetime)) {
    binStart.subtract(1, 'day');
  }

  const points: TSPlotReading[] = [];
  let readingIdx = 0;

  // Create one bin for each day until we've encompassed all of the readings
  while (binStart.isBefore(maxDatetime)) {
    const binEnd = binStart.clone().add(1, 'day');

    // Get the sum of all readings during this 24-hour period (end-inclusive)
    let sum: null | number = null;
    while (
      readingIdx < readingsLength &&
      moment(readings[readingIdx].reading_datetime).isSameOrBefore(binEnd)
    ) {
      const readingVal = readings[readingIdx].value;
      if (readingVal !== null) {
        sum = (sum ?? 0) + readingVal;
      }
      readingIdx++;
    }

    // Add points to the line that will draw the histogram
    if (sum === null) {
      // If there are multiple days in a row with no readings (`null` y),
      // we only need one `null` point in the plot.
      if (points[points.length - 1]?.y !== null) {
        // A point with a `null` for y causes a break in the line
        points.push({ x: binStart.toDate(), y: null, reading_id: 0 });
      }
    } else {
      // A horizontal line going the length of the bin
      points.push(
        {
          x: binStart.toDate(),
          y: sum,
          reading_id: 0,
        },
        {
          x: binEnd.toDate(),
          y: sum,
          reading_id: 0,
        }
      );
    }

    binStart.add(1, 'day');
  }

  return points;
}

/**
 * Helper function to add the "yVariance" value to readings that we need to show
 * confidence intervals for.
 *
 * NOTE: This function mutates the `plotReadings` array.
 *
 * @param plotReadings
 * @param errorBarReadings
 */
export function addErrorBarsToReadings(
  plotReadings: TSPlotReading[],
  errorBarReadings: Model.SimpleReading[]
) {
  const variances = errorBarReadings
    .map((r) => {
      const confidenceLevel = r.adjusted_reading_entries[1]?.value;
      if (confidenceLevel === undefined) {
        return null;
      }
      return {
        reading_id: r.id,
        // React-Vis draws the error bar to the exact length of `yVariance`.
        // The confidence levels from the back end are meant to be +/-, which
        // means the line's total length should be double that.
        yVariance: +confidenceLevel * 2,
      };
    })
    .filter(isNotNull);

  if (variances.length > 0) {
    // We only need to search the first few and last few readings to find the
    // ones to modify.
    const firstAndLastReadings = plotReadings
      .slice(0, 2)
      .concat(plotReadings.slice(-3));

    variances.forEach((v) => {
      const matchingReading = firstAndLastReadings.find(
        (r) => r.reading_id === v.reading_id
      );
      if (matchingReading) {
        matchingReading.yVariance = v.yVariance;
      }
    });
  }
}

export function fetchReadingsFromResolvedSettings(
  plotKey: string,
  resolvedSettings: PlotSettings | undefined,
  minDatetime: string | null,
  maxDatetime: string | null,
  paddedMinDatetime: string | null,
  paddedMaxDatetime: string | null,
  isZoomed: boolean
): StandardThunk {
  return async function (dispatch) {
    if (!resolvedSettings) {
      return;
    }

    if (resolvedSettings.plotType === Enum.PlotType.SCATTER) {
      return dispatch(
        fetchScatterPlotData(
          plotKey,
          resolvedSettings,
          minDatetime,
          maxDatetime,
          isZoomed
        )
      );
    }

    // Dispatch one readings fetch thunk for each observation point being plotted.
    // (The thunk will exit without fetching anything, if the data is already present.)
    return Promise.all(
      resolvedSettings.observationPoints.map(
        (obsPoint: Model.ObservationPointDecorated, idx: number) =>
          dispatch(
            fetchTimeSeriesPlotReadings(
              plotKey,
              obsPoint,
              resolvedSettings.reading_series[idx],
              paddedMinDatetime,
              paddedMaxDatetime,
              isZoomed
            )
          )
      )
    );
  };
}

export function fetchTimeSeriesPlotReadings(
  plotKey: string,
  observationPoint: Model.ObservationPointDecorated,
  seriesSettings: Pick<
    Optional<Model.StoredPlotItemReadingsSeries, 'id'>,
    'id' | 'item_number' | 'show_confidence_level'
  >,
  minDatetime: null | string,
  maxDatetime: null | string,
  isZoomed: boolean
): StandardThunk {
  return async function (dispatch, getState) {
    const readingSeriesId = seriesSettings.id;
    const itemNumber = seriesSettings.item_number;
    const key = buildReadingSeriesKey(plotKey, observationPoint.id, itemNumber);
    const buckets = observationPoint.histogram_day_cutoff_time
      ? null
      : TIMESERIES_BUCKETS;
    const zoomKey = `minDatetime=${minDatetime ?? 'null'}&maxDatetime=${
      maxDatetime ?? 'null'
    }:buckets=${buckets ?? 'null'}`;
    // Check to see if the required data is already in Redux. If it is, we can
    // exit the thunk without fetching anything.
    if (
      getState().plot.scatterTimeSeries.readingsSeries[key]?.zoomKey === zoomKey
    ) {
      return;
    }

    dispatch(ActionCreators.FETCH_READINGS_START(key, zoomKey));

    try {
      const filterParams: Filter.ReadingsPlot = {
        observation_point: observationPoint.id,
        item_number: itemNumber,
        reading_datetime_after: minDatetime
          ? formatDatetimeForBackendUrl(minDatetime)
          : undefined,
        reading_datetime_before: maxDatetime
          ? formatDatetimeForBackendUrl(maxDatetime)
          : undefined,
        // pad result with readings before and after requested date range, in order
        // to draw the plot to the edge of the chart area
        pad_readings: true,
        confidence_intervals: seriesSettings.show_confidence_level,
      };

      let plotReadings: TSPlotReading[];

      if (!buckets) {
        // For rainfall histogram, we have to request un-bucketed data.
        const rawData = await getApi('/readings/plot/', filterParams);
        plotReadings = sumRainfallHistogram(observationPoint, rawData.readings);
      } else {
        let rawData;
        if (readingSeriesId && !isZoomed) {
          rawData = await getApi(
            `/readings/plot-downsampled-reading-series/${readingSeriesId}/`
          );
        } else {
          rawData = await getApi('/readings/plot-downsampled/', {
            ...filterParams,
            buckets: TIMESERIES_BUCKETS,
          });
        }

        plotReadings = flattenReadingsBuckets(rawData);

        // Fetch fuller data about the readings we should show confidence
        // intervals for.
        if (
          seriesSettings.show_confidence_level &&
          rawData.confidence_interval_readings
        ) {
          addErrorBarsToReadings(
            plotReadings,
            rawData.confidence_interval_readings
          );
        }
      }

      return dispatch(
        ActionCreators.FETCH_READINGS_RESPONSE(key, zoomKey, plotReadings)
      );
    } catch (e) {
      return dispatch(
        ActionCreators.FETCH_READINGS_ERROR(key, zoomKey, errorToString(e))
      );
    }
  };
}

/**
 * A memoized selector to transform the raw alarm parameter values
 * into a format that's easier to deal with for plotting.
 *
 * Specifically, group them together by alarm type and level, and then within
 * each of those groups, sort them chronologically.
 *
 * @param {*} alarms
 * @returns
 */
export const selectAlarmsForPlotting = createSelector(
  (alarms: Array<Model.AlarmParameter | Model.ReportsAlarmParameter> | null) =>
    alarms,
  function (alarms): TSPlotAlarmParamSeries[] {
    if (!alarms) {
      return [];
    }
    const alarmsByLevel = alarms
      // Group them together by type
      .reduce(function (acc, item) {
        const key = `${item.level}.${item.type}`;
        if (!(key in acc)) {
          acc[key] = {
            type: item.type,
            level: item.level,
            thresholds: [],
          };
        }
        acc[key].thresholds.push({
          threshold: Number(item.threshold),
          start_datetime: item.start_datetime,
          end_datetime: item.end_datetime,
        });
        return acc;
      }, {} as Record<string, TSPlotAlarmParamSeries>);

    const alarmsForPlotting = Object.values(alarmsByLevel)
      // Sort the types by alarm level. (The way the plot is designed, we
      // need to layer the data over alert over design)
      .sort((groupA, groupB) =>
        compareAlarmParameterLevels(groupA.level, groupB.level)
      )
      // Within each grouping, sort the thresholds by start date
      .map(function (group) {
        group.thresholds = group.thresholds.sort((a, b) => {
          if (a.start_datetime < b.start_datetime) {
            return -1;
          } else if (a.start_datetime > b.start_datetime) {
            return 1;
          } else {
            return 0;
          }
        });
        return group;
      });

    return alarmsForPlotting;
  }
);

/**
 * Resolve the plot settings for the quickplot screen, keyed by a list of
 * "pairs" (observation point,item number).
 */
export function resolveSettingsFromQuickplot(
  pairs: null | ObservationPointItemUrlCode[],
  startDatetime: null | string,
  endDatetime: null | string,
  yAxisSides: string,
  axes: PlotSettingsAxis<QuickPlotAxisSide>[],
  numberOfMonths: null | number,
  showAnalysisComments: boolean,
  showInspectorComments: boolean,
  showMedia: boolean
): StandardThunk {
  return async (dispatch, getState, { i18n }) => {
    if (pairs === null) {
      return dispatch(ActionCreators.UNMOUNT_PLOT_PAGE());
    }

    // metadataHash is a string that uniquely identifies the combination of metadata
    // we need to fetch.
    const metadataHash = `QuickPlot: ${JSON.stringify(pairs)}`;
    const fullState = getState();

    const settings = {
      plotType: Enum.PlotType.TIME_SERIES,
      start_datetime: startDatetime,
      end_datetime: endDatetime,
      reading_series: pairs.map((_pair, i) => ({
        axis_side:
          yAxisSides[i] === 'R' ? ('right' as const) : ('left' as const),
        show_plot_port_rl: false,
        show_plot_markers: false,
        show_legend_cap_rl: false,
        show_legend_port_rl: false,
        show_confidence_level: false,
        // For compatibility with stored plots, we copy these flags onto each
        // readings series.
        show_analysis_comment_indicators: showAnalysisComments,
        show_inspector_comment_indicators: showInspectorComments,
        show_media_indicators: showMedia,
        show_alarm_parameters: false,
      })),
      axes,
      duration: formatIntervalForComputer(numberOfMonths, 'months'),
      // We also put these flags onto the plot itself, to properly populate
      // the settings form if the user opens it.
      showAnalysisComments,
      showInspectorComments,
      showMediaAll: showMedia,
    };

    if (
      fullState.plot.scatterTimeSeries.plots.quickPlot.resolved &&
      metadataHash ===
        fullState.plot.scatterTimeSeries.plots.quickPlot.metadataHash
    ) {
      // If the pairs are equal with what in the state, there is no need
      // to re-fetch the observation points.  We resolve immediately,
      // to sync the state with URL's params.
      const resolved =
        fullState.plot.scatterTimeSeries.plots.quickPlot.resolved;
      return dispatch(
        ActionCreators.RESOLVE_PLOT_SETTINGS_UPDATE('quickPlot', {
          ...resolved,
          ...settings,
          reading_series: resolved.reading_series.map((seriesSetting, idx) => ({
            ...seriesSetting,
            ...settings.reading_series[idx],
          })),
        } as QuickPlotSettings)
      );
    }

    dispatch(
      ActionCreators.RESOLVE_PLOT_SETTINGS_START('quickPlot', metadataHash)
    );

    try {
      // Fetch all the observation points.
      const rawObsPoints = await getApi('/observation-points/', {
        code__in: pairs.map((op) => op.observationPointCode),
      });

      // Put the observation points in the same order as the series.
      const observationPoints = pairs.map(({ observationPointCode }) => {
        const obsPoint = rawObsPoints.find(
          (op) => op.code === observationPointCode
        );
        if (!obsPoint) {
          throw new Error(
            i18n._(t`Observation point "${observationPointCode}" not found.`)
          );
        } else {
          return obsPoint;
        }
      });

      dispatch(
        ActionCreators.RESOLVE_PLOT_SETTINGS_RESPONSE(
          'quickPlot',
          metadataHash,
          {
            ...settings,
            observationPoints,
            reading_series: settings.reading_series.map((serie, idx) => ({
              ...serie,
              observation_point: observationPoints[idx].id,
              item_number: pairs[idx].itemNumber,
            })),
            interpolate: null,
            show_plot_markers: null,
            show_mark_connections: null,
            storedPlot: null,
            highlight_periods: [],
          } as QuickPlotSettings
        )
      );
    } catch (e) {
      dispatch(
        ActionCreators.RESOLVE_PLOT_SETTINGS_ERROR(
          'quickPlot',
          metadataHash,
          errorToString(e)
        )
      );
    }
  };
}

export function resolvePlotSettingsFromStoredPlot(
  storedPlot: StoredTimeSeriesPlotWithArea | StoredScatterPlotWithArea
): StandardThunk<
  ReturnType<typeof ActionCreators['RESOLVE_PLOT_SETTINGS_RESPONSE']>[]
> {
  return (dispatch, _getState, { i18n }) =>
    Promise.all(
      storedPlot.items.map(async (storedPlotItem) => {
        const plotKey = buildStoredPlotKey(storedPlot.id, storedPlotItem.id);
        // metadataHash uniquely identifies the metadata we need to fetch.
        // In this case, it can be just the stored plot's id.
        const metadataHash = plotKey;

        const obsPointIds = storedPlotItem.reading_series.map(
          (serie) => serie.observation_point
        );

        dispatch(
          ActionCreators.RESOLVE_PLOT_SETTINGS_START(plotKey, metadataHash)
        );

        const rawObsPoints: Model.ObservationPointDecorated[] = await getApi(
          `/observation-points/`,
          { id__in: obsPointIds }
        );
        // Put the observation points in the same order as the series.
        const observationPoints = obsPointIds.map((id) => {
          const obsPoint = rawObsPoints.find((op) => op.id === id);
          if (!obsPoint) {
            throw new Error(i18n._(t`Observation point "${id}" not found.`));
          }
          return obsPoint;
        });

        const resolvedSettings: PlotSettings = {
          plotType: storedPlot.plot_type,
          observationPoints,
          storedPlot,
          start_datetime:
            formatDatetimeForStorage(storedPlot.start_datetime) || null,
          end_datetime:
            formatDatetimeForStorage(storedPlot.end_datetime) || null,
          reading_series: storedPlotItem.reading_series.map((rs) => ({
            ...rs,
          })),
          axes: storedPlotItem.axes,
          highlight_periods: storedPlotItem.highlight_periods,
          duration: storedPlot.duration,
          showAnalysisComments: false,
          showInspectorComments: false,
          showMediaAll: false,
          interpolate: storedPlotItem.interpolate,
          show_plot_markers: storedPlotItem.show_plot_markers,
          show_mark_connections: storedPlotItem.show_mark_connections,
        };
        return dispatch(
          ActionCreators.RESOLVE_PLOT_SETTINGS_RESPONSE(
            plotKey,
            metadataHash,
            resolvedSettings as PolymorphicPlotSettings
          )
        );
      })
    );
}

export interface ScatterPlotSettingsFromUrl {
  xObservationPointCode: string;
  yObservationPointCode: string;
  xItemNumber: number;
  yItemNumber: number;
  startDatetime: null | string;
  endDatetime: null | string;
  axes: ScatterPlotSettings['axes'];
  zoomedFrom: null | string;
  numberOfMonths: null | number;
  interpolate: boolean;
  showPlotMarks: boolean;
  showMarkConnections: boolean;
}

export function resolveScatterPlotSettingsFromUrl(
  settingsFromUrl: ScatterPlotSettingsFromUrl
): StandardThunk {
  return async (dispatch, getState, { i18n }) => {
    const {
      xObservationPointCode,
      yObservationPointCode,
      xItemNumber,
      yItemNumber,
      startDatetime,
      endDatetime,
      numberOfMonths,
      axes,
    } = settingsFromUrl;

    if (
      !xObservationPointCode ||
      !yObservationPointCode ||
      !xItemNumber ||
      !yItemNumber
    ) {
      return dispatch(ActionCreators.UNMOUNT_PLOT_PAGE());
    }

    if (!xObservationPointCode || !yObservationPointCode) {
      return;
    }

    const pairs: ObservationPointItemUrlCode[] = [
      {
        observationPointCode: yObservationPointCode,
        itemNumber: yItemNumber,
      },
      {
        observationPointCode: xObservationPointCode,
        itemNumber: xItemNumber,
      },
    ];

    // metadataHash is a string that uniquely identifies the combination of metadata
    // we need to fetch.
    const metadataHash = `QuickScatterPlot: ${JSON.stringify(pairs)}`;
    const plotKey = 'quickScatterPlot';
    const fullState = getState();

    const settings = {
      plotType: Enum.PlotType.SCATTER,
      start_datetime: startDatetime,
      end_datetime: endDatetime,
      reading_series: pairs.map((_pair, i) => ({
        axis_side: i === 0 ? 'left' : ('bottom' as 'left' | 'bottom'),
        show_plot_port_rl: false,
        show_plot_markers: true,
        show_analysis_comment_indicators: false,
        show_inspector_comment_indicators: false,
        show_media_indicators: false,
        show_alarm_parameters: false,
        show_legend_cap_rl: false,
        show_legend_port_rl: false,
        show_confidence_level: false,
      })),
      axes,
      duration: formatIntervalForComputer(numberOfMonths, 'months'),
      showAnalysisComments: false,
      showInspectorComments: false,
      showMediaAll: false,
      interpolate: settingsFromUrl.interpolate,
      highlight_periods: [],
      show_plot_markers: settingsFromUrl.showPlotMarks,
      show_mark_connections: settingsFromUrl.showMarkConnections,
      storedPlot: null,
    };

    if (
      fullState.plot.scatterTimeSeries.plots.quickScatterPlot.resolved &&
      metadataHash ===
        fullState.plot.scatterTimeSeries.plots.quickScatterPlot.metadataHash
    ) {
      // if the pairs are equal with what in the state, there is no need
      // to re-fetch the observation points / formula outputs
      // we resolve immediately to sync the state with URL's params
      const resolved =
        fullState.plot.scatterTimeSeries.plots.quickScatterPlot.resolved;
      return dispatch(
        ActionCreators.RESOLVE_PLOT_SETTINGS_UPDATE(plotKey, {
          ...resolved,
          ...settings,
          reading_series: resolved.reading_series.map((seriesSetting, idx) => ({
            ...seriesSetting,
            ...settings.reading_series[idx],
          })),
        } as ScatterPlotSettings)
      );
    }

    dispatch(ActionCreators.RESOLVE_PLOT_SETTINGS_START(plotKey, metadataHash));

    try {
      const rawObsPoints = await getApi('/observation-points/', {
        code__in: [yObservationPointCode, xObservationPointCode],
      });

      // Put the observation points in the same order as the series.
      const observationPoints = [
        yObservationPointCode,
        xObservationPointCode,
      ].map((observationPointCode) => {
        const obsPoint = rawObsPoints.find(
          (op) => op.code === observationPointCode
        );
        if (!obsPoint) {
          throw new Error(
            i18n._(t`Observation point "${observationPointCode}" not found.`)
          );
        } else {
          return obsPoint;
        }
      });

      dispatch(
        ActionCreators.RESOLVE_PLOT_SETTINGS_RESPONSE(plotKey, metadataHash, {
          ...settings,
          reading_series: settings.reading_series.map((serie, idx) => ({
            ...serie,
            observation_point: observationPoints[idx].id,
            item_number: pairs[idx].itemNumber,
          })),
          observationPoints,
        } as ScatterPlotSettings)
      );
    } catch (e) {
      dispatch(
        ActionCreators.RESOLVE_PLOT_SETTINGS_ERROR(
          plotKey,
          metadataHash,
          errorToString(e)
        )
      );
    }
  };
}

/**
 * Fetch data for the scatter plot. The scatter plot uses two time series which,
 * like the series for the quickplot, are uniquely identified by a tuple of
 * (observation point code, formula output id).
 */
export function fetchScatterPlotData(
  plotKey: string,
  resolvedSettings: PlotSettings,
  minDatetime: null | string,
  maxDatetime: null | string,
  _isZoomed: boolean
): StandardThunk {
  return async function (dispatch, getState) {
    const yReadingSeries = resolvedSettings.reading_series[0];
    const xReadingSeries = resolvedSettings.reading_series[1];

    const yObservationPoint = resolvedSettings.observationPoints[0];
    const yItemNumber = yReadingSeries.item_number;

    const xObservationPoint = resolvedSettings.observationPoints[1];
    const xItemNumber = xReadingSeries.item_number;

    const ySeriesKey = buildReadingSeriesKey(
      plotKey,
      yObservationPoint.id,
      yItemNumber
    );

    const xSeriesKey = buildReadingSeriesKey(
      plotKey,
      xObservationPoint.id,
      xItemNumber
    );

    const zoomKey = `minDatetime=${minDatetime ?? 'null'}&maxDatetime=${
      maxDatetime ?? 'null'
    }`;

    const yStoredData =
      getState().plot.scatterTimeSeries.readingsSeries[ySeriesKey];

    // If partially or completed fetched data already don't fetch again
    if (yStoredData && yStoredData.zoomKey === zoomKey) {
      return;
    }

    dispatch(ActionCreators.FETCH_READINGS_START(ySeriesKey, zoomKey));
    dispatch(ActionCreators.FETCH_READINGS_START(xSeriesKey, zoomKey));

    try {
      const readingDateParams: Partial<Filter.ReadingsPlot> = {};

      if (minDatetime) {
        readingDateParams.reading_datetime_after =
          formatDatetimeForBackendUrl(minDatetime);
      }

      if (maxDatetime) {
        readingDateParams.reading_datetime_before =
          formatDatetimeForBackendUrl(maxDatetime);
      }

      const readings = await getApi('/readings/scatter-plot/', {
        y_observation_point: yObservationPoint.id,
        y_item_number: yItemNumber,
        x_observation_point: xObservationPoint.id,
        x_item_number: xItemNumber,
        ...readingDateParams,
      });

      dispatch(
        ActionCreators.FETCH_READINGS_RESPONSE(
          ySeriesKey,
          zoomKey,
          // scatter plot data matching expect readings are sorted
          orderBy(
            readings.y_readings,
            [(r) => r.reading_datetime],
            ['asc']
          ).map((reading) => ({
            x: new Date(reading.reading_datetime),
            y: reading.value,
            reading_id: reading.reading_id,
          }))
        )
      );

      return dispatch(
        ActionCreators.FETCH_READINGS_RESPONSE(
          xSeriesKey,
          zoomKey,
          // scatter plot data matching expect readings are sorted
          orderBy(
            readings.x_readings,
            [(r) => r.reading_datetime],
            ['asc']
          ).map((reading) => ({
            x: new Date(reading.reading_datetime),
            y: reading.value,
            reading_id: reading.reading_id,
          }))
        )
      );
    } catch (e) {
      return dispatch(
        ActionCreators.FETCH_READINGS_ERROR(
          ySeriesKey,
          zoomKey,
          errorToString(e)
        )
      );
    }
  };
}
