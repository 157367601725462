import React from 'react';
import { Trans } from '@lingui/macro';
import ModalContent from 'components/base/modal/modalcontent';
import { Form, Formik, FormikErrors } from 'formik';
import { FormItem, FormSection } from 'components/base/form/FormItem';
import {
  ObsPointItemMenu,
  ObsPointItemMenuOption,
  splitObsPointItemIdent,
} from 'components/modules/obs-point-item-menu/ObsPointItemMenu';
import { FieldError } from 'components/base/form/errornotice/errornotice';
import { IntegerField } from 'components/base/form/integer-field/IntegerField';
import ActionBlock from 'components/base/actionblock/actionblock';
import ButtonHideModal from 'components/base/modal/buttonhidemodal';
import { ButtonPrimary } from 'components/base/button/button';
import './CumulativePlotSettingsModal.scss';
import { useHistory } from 'react-router';
import { useIsMounted } from 'util/hooks';

export interface CumulativePlotFormValues {
  observation_point_item: string | null;
  observation_point_item_detail?: ObsPointItemMenuOption[];
  start_year: string;
  end_year: string;
}

type CumulativePlotModalProps = CumulativePlotFormValues & {
  hideModal: () => void;
};

function cumulativePlotSettingsToUrl(values: CumulativePlotFormValues) {
  const obsPointMenuItem = values.observation_point_item_detail?.[0];
  const itemNumber = splitObsPointItemIdent(
    values.observation_point_item
  )!.item_number;

  if (!itemNumber || !obsPointMenuItem || !obsPointMenuItem.observationPoint)
    return '';

  const observationPointCode = obsPointMenuItem.observationPoint.code;

  return `${encodeURIComponent(observationPointCode)}/${itemNumber}/${
    values.start_year
  }/${values.end_year}/`;
}

export function CumulativePlotSettingsModal(props: CumulativePlotModalProps) {
  const { hideModal, observation_point_item, start_year, end_year } = props;

  const history = useHistory();
  const isMounted = useIsMounted();

  const initialValues: CumulativePlotFormValues = {
    observation_point_item: observation_point_item ?? null,
    start_year: start_year,
    end_year: end_year,
  };

  const validate = React.useCallback((values: CumulativePlotFormValues) => {
    const errors: FormikErrors<CumulativePlotFormValues> = {};

    if (!values.observation_point_item) {
      errors.observation_point_item = (
        <Trans>Please select an observation point.</Trans>
      ) as any;
    }

    if (!values.start_year || String(values.start_year).length !== 4) {
      errors.start_year = (
        <Trans>Start year must be a valid 4 digit year.</Trans>
      ) as any;
    } else if (!values.end_year || String(values.end_year).length !== 4) {
      errors.start_year = (
        <Trans>End year must be a valid 4 digit year.</Trans>
      ) as any;
    } else if (
      values.end_year &&
      values.start_year &&
      +values.start_year > +values.end_year
    ) {
      errors.start_year = (
        <Trans>End year must be equal to or greater than start year.</Trans>
      ) as any;
    }

    return errors;
  }, []);

  const handleSubmit = React.useCallback(
    (values: CumulativePlotFormValues) => {
      const url = cumulativePlotSettingsToUrl(values);
      history.push(`/cumulativeplot/${url}`);

      if (isMounted()) hideModal();
    },
    [history, isMounted, hideModal]
  );

  return (
    <ModalContent header={<Trans>Plot settings</Trans>}>
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form>
            {formik.status}
            <FormSection label={<Trans>Observation point</Trans>}>
              <ObsPointItemMenu
                name="observation_point_item"
                isMulti={false}
                detailsName={'observation_point_item_detail'}
              />
              <FieldError name="observation_point_item" />
            </FormSection>
            <FormSection
              label={<Trans>Time period</Trans>}
              className="cumulative-settings-plot-time-period"
            >
              <FormItem
                label={<Trans>Start year</Trans>}
                fieldId="start_year"
                className="start-year"
              >
                <IntegerField name="start_year" maxLength={4} />
              </FormItem>
              <FormItem label={<Trans>End year</Trans>} fieldId="end_year">
                <IntegerField name="end_year" maxLength={4} />
              </FormItem>
              <FieldError name="start_year" />
            </FormSection>

            <ActionBlock>
              <ButtonHideModal />
              <ButtonPrimary
                type="submit"
                iconType="icon-update"
                disabled={formik.isSubmitting}
              >
                <Trans>Apply settings</Trans>
              </ButtonPrimary>
            </ActionBlock>
          </Form>
        )}
      </Formik>
    </ModalContent>
  );
}
