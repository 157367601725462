import React from 'react';
import { Trans } from '@lingui/macro';
import ActionBlock from 'components/base/actionblock/actionblock';
import { AlertDanger, AlertWarning } from 'components/base/alert/alert';
import Loading from 'components/base/loading/loading';
import ButtonShowModal from 'components/base/modal/buttonshowmodal';
import PageStandard from 'components/modules/pagestandard/pagestandard';
import { CumulativePlotSettingsModal } from './CumulativePlotSettingsModal';
import {
  CumulativePlotReadings,
  ObservationPointDecorated,
} from 'util/backendapi/types/Model';
import { CumulativePlot } from 'components/plots/CumulativePlot';
import { MessageDescriptor } from '@lingui/core';
import { HasPermission } from 'components/logic/has-permission/HasPermission';
import { SavePlotModal } from 'screens/quickplot/SavePlotModal';
import { PlotType } from 'util/backendapi/types/Enum';

export interface CumulativePlotViewProps {
  observationPoint: ObservationPointDecorated | null;
  cumulativePlotReadings: CumulativePlotReadings | null;
  isLoading: boolean;
  errorMessage: string | MessageDescriptor | null;
  itemNumber: number;
  startYear: number;
  endYear: number;
}

export interface CumulativePlotSettings {
  observation_point_item: string;
  start_year: string;
  end_year: string;
}

export function CumulativePlotView(props: CumulativePlotViewProps) {
  const {
    observationPoint,
    cumulativePlotReadings,
    isLoading,
    errorMessage,
    itemNumber,
    startYear,
    endYear,
  } = props;

  const pageProps = {
    name: 'cumulativeplot',
    header: <Trans>Cumulative Plot</Trans>,
    subHeader: observationPoint?.code,
  };

  if (isLoading) {
    return (
      <PageStandard {...pageProps}>
        <Loading />
      </PageStandard>
    );
  }

  const displayedYearRange =
    startYear && endYear ? (
      <div className="plot-page-header-info">
        <h2 data-testid="plot-date-range">
          {startYear} <Trans>to</Trans> {endYear}
        </h2>
      </div>
    ) : null;

  const cumulativePlotSettings: CumulativePlotSettings = {
    observation_point_item:
      observationPoint && itemNumber
        ? `${observationPoint.id}_${itemNumber}`
        : '',
    start_year: startYear ? String(startYear) : '',
    end_year: endYear ? String(endYear) : '',
  };

  const plotHeader = (
    <div className="page-content-header columns-fluid">
      {displayedYearRange}
      <ActionBlock className="text-right">
        <HasPermission check="can_create_stored_plots">
          <ButtonShowModal
            name="save-plot"
            iconType="icon-save"
            disabled={
              !(
                cumulativePlotSettings.observation_point_item !== '' &&
                Number(cumulativePlotSettings.start_year) &&
                Number(cumulativePlotSettings.end_year) &&
                Number(cumulativePlotSettings.end_year) >=
                  Number(cumulativePlotSettings.start_year)
              )
            }
            modalContent={() => (
              <SavePlotModal plotType={PlotType.CUMULATIVE} />
            )}
          >
            <Trans>Save</Trans>
          </ButtonShowModal>
        </HasPermission>
        <ButtonShowModal
          name="settings"
          iconType="icon-cog"
          shortcut="GOTO_SETTINGS"
          autoShow={!observationPoint && !itemNumber}
          modalContent={({ hideModal }: { hideModal: any }) => (
            <CumulativePlotSettingsModal
              hideModal={hideModal}
              {...cumulativePlotSettings}
            />
          )}
        >
          <Trans>Settings</Trans>
        </ButtonShowModal>
      </ActionBlock>
    </div>
  );

  if (errorMessage) {
    return (
      <PageStandard {...pageProps}>
        {plotHeader}
        <AlertDanger>{errorMessage}</AlertDanger>
      </PageStandard>
    );
  }

  if (
    cumulativePlotReadings !== null &&
    Object.keys(cumulativePlotReadings.readings).length === 0
  ) {
    return (
      <PageStandard {...pageProps}>
        {plotHeader}
        <AlertWarning>No data</AlertWarning>
      </PageStandard>
    );
  }

  return (
    <PageStandard {...pageProps}>
      {plotHeader}

      {observationPoint && cumulativePlotReadings ? (
        <CumulativePlot
          observationPoint={observationPoint}
          itemNumber={itemNumber}
          cumulativePlotReadings={cumulativePlotReadings}
          startYear={startYear}
          endYear={endYear}
        />
      ) : null}
    </PageStandard>
  );
}
