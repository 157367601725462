import React from 'react';
import { selectCumulativePlotData } from 'ducks/plot/cumulative';
import { FullState } from 'main/reducers';
import { useSelector } from 'react-redux';
import { CumulativePlot } from './CumulativePlot';
import { min, max } from 'lodash';
import ErrorNotice from 'components/base/form/errornotice/errornotice';
import Loading from 'components/base/loading/loading';
import { StoredCumulativePlotWithArea } from 'ducks/stored-plot/detail';
import { Trans } from '@lingui/macro';

interface Props {
  storedPlot: StoredCumulativePlotWithArea;
}

export function CumulativeStoredPlot(props: Props) {
  const { storedPlot } = props;

  const plotState = useSelector((state: FullState) =>
    selectCumulativePlotData(state, storedPlot.id)
  );

  const errorMessage = plotState?.errorMessage ?? null;
  const isLoading = plotState?.isLoading ?? false;

  if (errorMessage) {
    return <ErrorNotice>{errorMessage}</ErrorNotice>;
  }

  if (isLoading) {
    return <Loading />;
  }

  const observationPoint = plotState?.observationPoints?.[0] ?? null;
  const itemNumber = plotState?.observationPointItems?.[0].item_number;
  const plotReadings = plotState?.plotReadings ?? null;

  const timePeriods = plotState?.timePeriods ?? [];

  if (
    !observationPoint ||
    !itemNumber ||
    !plotReadings ||
    timePeriods.length === 0
  ) {
    return null;
  }

  const startYear = min(timePeriods);
  const endYear = max(timePeriods);

  return (
    <>
      <div className="plot-item-detail">
        <h3 className="plot-item-header">{storedPlot.title}</h3>
        <p data-testid="plot-date-range">
          {startYear} <Trans>to</Trans> {endYear}
        </p>
      </div>
      <CumulativePlot
        observationPoint={observationPoint}
        cumulativePlotReadings={plotReadings}
        itemNumber={itemNumber}
        startYear={startYear!}
        endYear={endYear!}
      />
    </>
  );
}
