import React, { useCallback, useMemo } from 'react';
import orderBy from 'lodash/orderBy';
import range from 'lodash/range';
import { SectionProps } from '../maintobspoint.types';
import EditableCard from 'components/base/form/editablecard/editablecard';
import { Trans } from '@lingui/macro';
import { FormikHelpers, FastField, FormikErrors, Field } from 'formik';
import { FieldError } from 'components/base/form/errornotice/errornotice';
import { IntervalDisplay } from 'components/base/i18n/IntervalDisplay';
import {
  IntervalField,
  IntervalFieldValue,
} from 'components/base/form/interval/intervalfield';
import {
  IntegerField,
  IntegerFieldValue,
} from 'components/base/form/integer-field/IntegerField';
import { formatDatetimeForDisplay } from 'util/dates';
import { showErrorsInFormik } from 'util/backendapi/error-formik';
import Loading from 'components/base/loading/loading';
import { getExpectedFields } from 'util/backendapi/error';
import { useSelector } from 'react-redux';
import { FullState } from 'main/reducers';
import { selectAll, EntityTypes } from 'ducks/entities';
import { CardSectionField } from 'components/base/card/card';
import { Model } from 'util/backendapi/models/api.interfaces';

interface MyFormValues {
  reading_frequency: IntervalFieldValue;
  reading_frequency_tolerance: IntervalFieldValue;
  device_alarm_threshold: IntegerFieldValue;
  input_labels: string[];
  route_march_instruction: string;
}
type MyFormikErrors = FormikErrors<MyFormValues>;
type MyFormikHelpers = FormikHelpers<MyFormValues>;

type Props = SectionProps;

function validate(values: MyFormValues): MyFormikErrors {
  const errors: MyFormikErrors = {};

  if (values.reading_frequency && !values.reading_frequency_tolerance) {
    errors.reading_frequency_tolerance = (
      <Trans>
        Frequency tolerance is required, when default reading frequency has been
        supplied.
      </Trans>
    ) as any as string;
  }

  if (values.reading_frequency_tolerance && !values.reading_frequency) {
    errors.reading_frequency = (
      <Trans>
        Default reading frequency is required, when frequency tolerance has been
        supplied.
      </Trans>
    ) as any as string;
  }
  return errors;
}

export default function ObsPointReadingsSection(props: Props) {
  const shouldDisable =
    props.isDisabled || props.isLoading || props.isSubmitting;

  const { formulaSnapshots, formulas } = useSelector((state: FullState) => {
    const {
      historyForObservationPoint,
      formulaSnapshots,
      isLoading,
      errorMessage,
    } = state.obsPoint.detail.formula;
    return {
      formulaSnapshots:
        historyForObservationPoint?.code === props.observationPoint?.code
          ? formulaSnapshots
          : null,
      isLoadingFormula: isLoading,
      errorLoadingFormula: errorMessage,
      formulas: selectAll(state, EntityTypes.FORMULA).byId,
    };
  });
  const formulaInputs = useMemo(() => {
    if (!formulaSnapshots || Object.keys(formulas).length === 0) {
      // Data not yet loaded.
      return null;
    }
    if (formulaSnapshots.length === 0) {
      // No formula assigned to this obs point yet.
      return [];
    }
    const latestSnapshot = orderBy(
      formulaSnapshots,
      (fs) => fs.start_datetime,
      'desc'
    )[0];
    const latestFormula =
      formulas[latestSnapshot.observation_point_formula.formula];
    if (!latestFormula) {
      // Invalid formula id assigned to this observation point.
      return [];
    } else {
      return orderBy(
        latestFormula.formula_inputs.filter(
          (fi): fi is Model.FormulaInput & { position: number } =>
            fi.position !== null
        ),
        (fi) => fi.position
      );
    }
  }, [formulaSnapshots, formulas]);

  const handleSubmit = useCallback(
    async (values: MyFormValues, formik: MyFormikHelpers) => {
      try {
        await props.onSubmit.call(null, {
          ...values,
          device_alarm_threshold:
            values.device_alarm_threshold === ''
              ? null
              : values.device_alarm_threshold,
          input_labels: values.input_labels
            .map((label, idx) => ({
              label,
              position: idx + 1,
            }))
            .filter(({ label }) => label !== ''),
        });
      } catch (e) {
        showErrorsInFormik(formik, e, getExpectedFields(values));
        formik.setSubmitting(false);
      }
    },
    [props.onSubmit]
  );

  const { observationPoint: op } = props;
  if (!op || !formulaInputs) {
    return <Loading />;
  }
  const numberOfInputLabels = Math.max(
    op.input_labels.length,
    ...formulaInputs.map((fi) => fi.position)
  );

  const initialValues: MyFormValues = {
    reading_frequency: op.reading_frequency || null,
    reading_frequency_tolerance: op.reading_frequency_tolerance || null,
    device_alarm_threshold:
      op.device_alarm_threshold === null ? '' : op.device_alarm_threshold,
    input_labels: range(0, numberOfInputLabels).map(
      (i) =>
        op.input_labels.find(({ position }) => position === i + 1)?.label ?? ''
    ),
    route_march_instruction: op.route_march_instruction,
  };

  return (
    <EditableCard
      key={String(props.isEditing)}
      name="readings"
      header={<Trans>Readings</Trans>}
      shouldDisable={shouldDisable}
      hasEditPermission={props.hasEditPermission}
      isEditMode={props.isEditing}
      startEditing={props.startEditing}
      stopEditing={props.stopEditing}
      onSubmit={handleSubmit}
      validate={validate}
      initialValues={initialValues}
      render={({ formik, CardSectionComponent }) => {
        return (
          <>
            {formik.status}
            <CardSectionComponent
              name="monitoring-dates"
              header={<Trans>Monitoring dates</Trans>}
              fields={[
                {
                  name: 'readings-firstReadingDatetime',
                  label: <Trans>First reading</Trans>,
                  content: op.earliest_reading && (
                    <p className="non-editable-value">
                      {formatDatetimeForDisplay(
                        op.earliest_reading.reading_datetime,
                        op.time_zone.name
                      )}
                    </p>
                  ),
                },
                {
                  name: 'lastReadingDatetime',
                  label: <Trans>Latest reading</Trans>,
                  content: op.latest_reading && (
                    <p className="non-editable-value">
                      {formatDatetimeForDisplay(
                        op.latest_reading.reading_datetime,
                        op.time_zone.name
                      )}
                    </p>
                  ),
                },
                {
                  name: 'nextReading',
                  label: <Trans>Next reading</Trans>,
                  content: op.next_reading_datetime && (
                    <p className="non-editable-value">
                      {formatDatetimeForDisplay(
                        op.next_reading_datetime,
                        op.time_zone.name
                      )}
                    </p>
                  ),
                },
                {
                  name: 'activeReadingFrequency',
                  label: <Trans>Active reading frequency</Trans>,
                  content: op.active_reading_frequency && (
                    <p className="non-editable-value">
                      <IntervalDisplay value={op.active_reading_frequency} />{' '}
                      {op.active_reading_frequency_route_march && (
                        <Trans>
                          (Route march:{' '}
                          {op.active_reading_frequency_route_march.code})
                        </Trans>
                      )}{' '}
                      {op.active_reading_frequency_data_logger && (
                        <Trans>
                          (Data logger:{' '}
                          {
                            op.active_reading_frequency_data_logger
                              .logger_number
                          }
                          )
                        </Trans>
                      )}
                    </p>
                  ),
                },
              ]}
            />
            <CardSectionComponent
              name="readingsFrequency"
              header={<Trans>Frequency</Trans>}
              fields={[
                {
                  name: 'reading_frequency',
                  label: <Trans>Default reading frequency</Trans>,
                  formGroupClassName: 'form-group-interval-select',
                  content: props.isEditing ? (
                    <>
                      <IntervalField
                        name="reading_frequency"
                        data-testid="reading_frequency-input"
                        disabled={shouldDisable}
                      />
                      <FieldError name="reading_frequency" />
                    </>
                  ) : (
                    <IntervalDisplay value={op.reading_frequency} />
                  ),
                },
                {
                  name: 'reading_frequency_tolerance',
                  label: <Trans>Frequency tolerance</Trans>,
                  formGroupClassName: 'form-group-interval-select',
                  content: props.isEditing ? (
                    <>
                      <IntervalField
                        name="reading_frequency_tolerance"
                        data-testid="reading_frequency_tolerance"
                        disabled={shouldDisable}
                      />
                      <FieldError name="reading_frequency_tolerance" />
                    </>
                  ) : (
                    <IntervalDisplay value={op.reading_frequency_tolerance} />
                  ),
                },
              ]}
            />
            <CardSectionComponent
              name="routeMarch"
              header={<Trans>Route march</Trans>}
              fields={[
                {
                  name: 'device_alarm_threshold',
                  label: <Trans>Device alarm</Trans>,
                  content: props.isEditing ? (
                    <div className="input-unit-wrapper">
                      <IntegerField
                        min={0}
                        name="device_alarm_threshold"
                        data-testid="device_alarm_threshold"
                        disabled={shouldDisable}
                        className="text-input-with-unit"
                      />
                      <span className="input-unit">%</span>
                      <FieldError name="device_alarm_threshold" />
                    </div>
                  ) : (
                    <p>
                      {op.device_alarm_threshold === null
                        ? '-'
                        : op.device_alarm_threshold}
                    </p>
                  ),
                },
                ...range(0, numberOfInputLabels).map(
                  (idx): CardSectionField => {
                    const position = idx + 1;
                    const fi = formulaInputs.find(
                      (fi) => fi.position === position
                    );
                    return {
                      name: `input-label-${position}`,
                      label: (
                        <Trans>Input label - reading item {position}</Trans>
                      ),
                      content: props.isEditing ? (
                        <>
                          <Field
                            data-testid={`input-label-${position}`}
                            disabled={shouldDisable}
                            type="text"
                            maxLength={255}
                            name={`input_labels[${idx}]`}
                            placeholder={fi?.description}
                          />
                          <FieldError name={`input_labels[${idx}]`} />
                        </>
                      ) : (
                        formik.values.input_labels[idx] || fi?.description
                      ),
                    };
                  }
                ),
                {
                  name: 'route_march_instruction',
                  label: <Trans>Route march instruction</Trans>,
                  content: props.isEditing ? (
                    <>
                      <FastField
                        data-testid="route_march_instruction"
                        disabled={shouldDisable}
                        component="textarea"
                        maxLength={500}
                        name="route_march_instruction"
                      />
                      <FieldError name="route_march_instruction" />
                    </>
                  ) : (
                    <span className="text-with-linebreaks">
                      {op.route_march_instruction}
                    </span>
                  ),
                },
              ]}
            />
          </>
        );
      }}
    />
  );
}
