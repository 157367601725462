import { extent, max } from 'd3-array';
import orderBy from 'lodash/orderBy';
import sortBy from 'lodash/sortBy';
import findIndex from 'lodash/findIndex';
import { createSelector } from 'reselect';
import { transEnum } from 'util/i18n-utils';
import { Enum } from 'util/backendapi/models/api.interfaces';
import {
  TimeSeriesPlotOwnProps,
  getColorForIdx,
  getMarkerForIdx,
  getLabelForIdx,
} from './timeseriesplot';
import { RVTickFormat } from 'react-vis';
import { isNotNull, isTruthy } from 'util/validation';
import {
  TSPlotAlarmParamLabel,
  PlotReadingsSeries,
  TSPlotAlarmParamBox,
  TSPlotAlarmParamOutlinePoint,
  TSPlotAlarmParamSeriesWithShapes,
  TSPlotMouseoverPoint,
  TSPlotYAxisScales,
  Y_AXIS_SIDES,
  YAxisSide,
  TimeSeriesPlotSettings,
  PlotSettings,
  PlotSettingsReadingSeries,
  ObservationPointItemUrlCode,
  TSPlotReading,
} from './timeseriesplot.types';
import { parseIntervalFromString } from 'util/dates';
import { StoredTimeSeriesPlotWithArea } from 'ducks/stored-plot/detail';
import {
  StoredPlotItemAxis,
  StoredPlotAnnotation,
  StoredPlotDatetimeAnnotation,
  StoredPlotNumericAnnotation,
} from 'util/backendapi/types/Model';
import { getSafely } from 'util/misc';
import { QuickPlotSettingsFormValue } from './settingsform.types';
import { tzInterval } from './tzInterval';
import { scaleLinear } from 'd3-scale';
import { findLastIndex } from 'lodash';
import moment from 'moment-timezone';
import {
  StoredPlotAnnotationLabelPosition,
  StoredPlotAnnotation_STUB_POSITION,
} from 'util/backendapi/types/Enum';

// The subset of props used by some of the selectors.
interface CalculateXDomainProps {
  paddedMinDatetime: string | null;
  paddedMaxDatetime: string | null;
}
export interface SelectSeriesByAxisProps extends CalculateXDomainProps {
  readingsSeries: {
    readings: PlotReadingsSeries['readings'];
    settings: Pick<PlotSettingsReadingSeries, 'axis_side'>;
    observationPoint: PlotReadingsSeries['observationPoint'];
  }[];
}

/**
 * Collected data used for the `domains` props on graph components in a time
 * series plot.
 */
type TSPlotDomains = {
  [K in YAxisSide]: [number, number] | null;
} & {
  bottom: [Date, Date];
};

/**
 * The data we generate about how to draw X axis ticks, tick labels, and grid lines
 */
export interface TimeSeriesXTicks {
  // To avoid complaints from React-Vis's proptypes, we have to provide the
  // x tick values as epoch numbers instead of Date objects.
  xTicks: number[];
  xGridLines: number[];
  xTickFormat: RVTickFormat;
}

/**
 * A factory function for making some interconnected memoizing selectors used in
 * time series plots. We need a factory function because there can be multiple
 * time series plots displayed on the same page, and we need to make sure they
 * don't share selectors or they'll spoil each others' memoization.
 *
 * (If I were starting over, I might do this by making these into non-memoized
 * functions, and just memoizing their output with `React.useMemo()`)
 */
export function makeTimeSeriesPlotSelectors() {
  const calculateXDomain = createSelector(
    (props: CalculateXDomainProps) => props.paddedMinDatetime,
    (props: CalculateXDomainProps) => props.paddedMaxDatetime,

    function (paddedMinDatetime, paddedMaxDatetime): [Date, Date] | undefined {
      // Start and end date provided; just use those
      if (paddedMinDatetime && paddedMaxDatetime) {
        return [new Date(paddedMinDatetime), new Date(paddedMaxDatetime)];
      } else {
        return undefined;
      }
    }
  );

  const calculateSeriesLabels = createSelector(
    (
      props: CalculateXDomainProps & {
        readingsSeries: Pick<PlotReadingsSeries, 'readings'>[];
      }
    ) => props.readingsSeries,
    calculateXDomain,
    function (readingsSeries, xDomain): SerieLabelPosition[][] {
      if (!xDomain) {
        return readingsSeries.map(() => []);
      }
      const [xMin, xMax] = xDomain;

      return readingsSeries.map(({ readings }) => {
        if (
          !readings ||
          // No readings
          readings.length === 0 ||
          // All readings are outside the plot (after the end date)
          readings[0].x > xMax ||
          // All readings are outside the plot (before the start date)
          readings[readings.length - 1].x < xMin
        ) {
          return [];
        }

        let leftLabel: (SerieLabelPosition & { side: 'left' }) | null = null;
        let rightLabel: (SerieLabelPosition & { side: 'right' }) | null = null;

        // Figure out where to place the label on the left side of the series.
        for (let i = 0; i < readings.length; i++) {
          const curReading = readings[i];
          if (curReading.y === null) {
            // A null reading is not actually displayed on the plot (it represents
            // a break in the line). So we can't put the label next to it.
            continue;
          }

          const curX = curReading.x;

          if (curX > xMax) {
            // None of the readings inside the plot were non-null! Nowhere to print
            // a label.
            return [];
          }

          if (curX >= xMin && curReading.y !== null) {
            // We're past the left axis. Put the label by the first non-null reading.
            leftLabel = {
              x: new Date(curX),
              y: curReading.y!,
              side: 'left',
            };
            break;
          }

          const nextReading = readings[i + 1] as TSPlotReading | undefined;
          const nextX = nextReading?.x;

          if (
            nextReading !== undefined &&
            nextX !== undefined &&
            nextReading.y !== null &&
            curX < xMin &&
            nextX > xMin
          ) {
            // The segment between this reading and the next crosses the left axis.
            // so calculate where it crosses the axis, and put the label there.
            const interpolator = scaleLinear()
              .domain([curX, nextX])
              .range([curReading.y!, nextReading.y!]);
            leftLabel = {
              side: 'left',
              x: new Date(xMin),
              y: interpolator(xMin),
            };
            break;
          }
        }

        // Figure out where to place the label on the right side of the series.
        // TODO: Lots of duplicate code between this and the left side calc. Can it
        // be de-duped?
        for (let i = readings.length - 1; i >= 0; i--) {
          const curReading = readings[i];
          if (curReading.y === null) {
            // A null reading is not actually displayed on the plot (it represents
            // a break in the line). So we can't put the label next to it.
            continue;
          }

          const curX = curReading.x;

          if (curX <= xMax && curReading.y !== null) {
            // We're past the right axis. Put the label by the first non-null reading.
            rightLabel = {
              x: new Date(curX),
              y: curReading.y!,
              side: 'right',
            };
            break;
          }

          const nextReading = readings[i - 1] as TSPlotReading | undefined;
          const nextX = nextReading?.x;

          if (
            nextReading !== undefined &&
            nextX !== undefined &&
            nextReading.y !== null &&
            curX > xMax &&
            nextX < xMax
          ) {
            // The segment between this reading and the next crosses the left axis.
            // so calculate where it crosses the axis, and put the label there.
            const interpolator = scaleLinear()
              .domain([curX, nextX])
              .range([curReading.y!, nextReading.y!]);
            rightLabel = {
              x: new Date(xMax),
              y: interpolator(xMax),
              side: 'right',
            };
            break;
          }
        }

        return [leftLabel, rightLabel].filter(isNotNull);
      });
    }
  );

  const selectSeriesByAxis = createSelector(
    (props: SelectSeriesByAxisProps) => props.readingsSeries,
    calculateSeriesLabels,
    function (readingsSeries, seriesLabels) {
      const seriesWithLabels = readingsSeries.map((rs, idx) => ({
        ...rs,
        labels: seriesLabels[idx],
      }));
      return Object.fromEntries(
        Y_AXIS_SIDES.map((side) => [
          side,
          seriesWithLabels.filter((rs) => rs.settings.axis_side === side),
        ])
      );
    }
  );

  /**
   * A memoized function to calculate the Y domains (the range of readings values
   * to plot along the Y axes).
   */
  const calculateYDomains = createSelector(
    selectSeriesByAxis,
    calculateXDomain,
    (props: Pick<TimeSeriesPlotOwnProps, 'yAxes'>) => props.yAxes,
    function (readingsSeries, xDomain, y_axes) {
      return Object.fromEntries(
        Y_AXIS_SIDES.map((side) => [
          side,
          _calcYDomain(side, y_axes, readingsSeries, xDomain),
        ])
      );
    }
  );
  function _calcYDomain(
    side: YAxisSide,
    yAxes: StoredPlotItemAxis[],
    seriesByAxis: ReturnType<typeof selectSeriesByAxis>,
    xDomain: [Date, Date] | undefined
  ): [number, number] | null {
    // Check if they manually specified a custom axis domain.
    const customAxis = yAxes.find((a) => a.side === side);
    if (
      customAxis &&
      customAxis.minimum !== null &&
      customAxis.maximum !== null
    ) {
      // Use the custom axis domain.
      return [+customAxis.minimum, +customAxis.maximum];
    }

    // No custom domain. Determine the default domain, based on the readings
    // being plotted to this axis.
    const readingsSeriesOnThisAxis = seriesByAxis[side];
    const allReadingsOnThisAxis = readingsSeriesOnThisAxis
      .map(({ readings, labels }) => {
        // We want to include the vertical positions of all the readings that are
        // in the x domain of the plot. And the vertical positions where the
        // data lines reach the edge of the plot (represented by the positions
        // of the series labels)
        if (!readings) {
          return labels;
        }
        if (!xDomain) {
          return [...readings, ...labels];
        }
        // Filter out readings that are outside the plot. We do this using
        // "findIndex" and "findLastIndex" in order to avoid unnecessarily
        // applying a filter comparison to every reading.
        const firstIdxInPlot = readings.findIndex((r) => r.x >= xDomain[0]);
        const lastIdxInPlot = findLastIndex(readings, (r) => r.x <= xDomain[1]);
        return [...readings.slice(firstIdxInPlot, lastIdxInPlot), ...labels];
      })
      .filter(isTruthy)
      .flat();

    // No data being plotted on this axis; we don't need a domain for this axis.
    if (allReadingsOnThisAxis.length === 0) {
      return null;
    }

    // Find the min/max values being plotted.
    const [yMin, yMax] = extent(allReadingsOnThisAxis, (a) => a.y) as [
      number,
      number
    ];
    const dataDomainSize = yMax - yMin;

    // Find the "min domain size" values on the instruments being plotted.
    const instrumentMinDomainSize =
      // (Only the largest "min domain size" has an effect)
      // Using d3's "max()" function rather than Math.max() because it handles
      // `null` and `NaN` better.
      max(readingsSeriesOnThisAxis, (serie) =>
        Number(serie.observationPoint.instrument_type.default_y_axis_range)
      ) || 0;

    // The default domain size is a 10% padding around the data being plotted,
    // unless that is smaller than the "min domain size" specified by the instrument.
    const defaultYDomainSize = max([
      instrumentMinDomainSize,
      dataDomainSize * 1.1,
    ]) as number;

    // Now that we know the *size* of the domain, calculate an actual domain with
    // that size, centered around the data being plotted.
    const yDomainMiddle = (yMax + yMin) / 2;
    return [
      yDomainMiddle - defaultYDomainSize / 2,
      yDomainMiddle + defaultYDomainSize / 2,
    ];
  }

  const calculateAxisDomains = createSelector(
    calculateXDomain,
    calculateYDomains,
    function (xDomain, yDomains): TSPlotDomains | undefined {
      if (!xDomain) {
        return undefined;
      }
      return {
        ...yDomains,
        bottom: xDomain,
      };
    }
  );

  /**
   * Calculate X axis ticks and labels
   *
   * @param {array} data
   * @returns {xTicks: array|undefined, xTickFormat: func|undefined}
   */
  const calculateXTicks = createSelector(
    (
      props: CalculateXDomainProps & {
        timeZone?: string | null;
      }
    ) => props.timeZone,
    calculateXDomain,
    function (timeZone, xDomain): TimeSeriesXTicks {
      if (!xDomain || !timeZone) {
        return {
          xTicks: [],
          xGridLines: [],
          xTickFormat: () => '',
        };
      }

      const [startDate, endDate] = xDomain;
      const startMoment = moment.tz(startDate, timeZone);
      // HACK: Because we auto-fill the end date's time to "23:59:59", if you
      // try to make a plot for exactly one day, you actually get a plot for
      // 1 second *less* than one day. So to line it up with the zoom levels,
      // we add 1 second back, here.
      const endMoment = moment.tz(endDate, timeZone).add(1, 'second');

      // Date formats used at different zoom levels.
      const YEAR_ONLY = 'YYYY';
      const MONTH_YEAR = 'MMM-YYYY';
      const DAY_MONTH_YEAR = 'D-MMM-YYYY';
      const HOUR_MIN_SEC = 'HH:mm';
      // A couple of the zoom levels use a mix of "DAY_MONTH_YEAR" and
      // "HOUR_MIN_SEC", which requires a function to decide which.
      const DAY_OR_HOUR: RVTickFormat = (tickDate, index) => {
        const tickMoment = moment.tz(tickDate, timeZone);
        return tickMoment.format(
          index === 0 || tickMoment.hours() === 0
            ? DAY_MONTH_YEAR
            : HOUR_MIN_SEC
        );
      };

      // The general pattern we need to follow is the same at all zoom levels.
      // This function extracts out that repeated logic, so that we just have
      // to specify the part that changes at each zoom level.
      const makeTicks = function (config: {
        tickStep: number;
        tickUnit: 'year' | 'month' | 'day' | 'hour';
        gridStep?: number;
        gridUnit?: 'year' | 'month' | 'day' | 'hour';
        // Format for the tick labels.
        labelFormat?: string;
        // Format for the first tick label (when it's different)
        firstLabelFormat?: string;
        // A function to calculate the tick label (for zoom levels that use
        // a mix of formats beyond just the first tick)
        labelFormatFn?: RVTickFormat;
      }) {
        const {
          tickStep,
          tickUnit,
          gridStep,
          gridUnit,
          labelFormat,
          firstLabelFormat,
          labelFormatFn,
        } = config;

        const xTicks = tzInterval(timeZone, tickUnit)
          // `.every()` filters the interval to only those boundaries that are
          // evenly divided by the provided number of steps. For example,
          // `timeHours().every(3)` would give you an interval that returns
          // interval boundaries at hours divisible by 3: "3am, 6am, 9am..."
          //
          // This works great for everything except the "day" interval, where
          // it's based on day of the month and resets each month. That would
          // give us evenly *numbered* but unevenly *spaced* grid lines such
          // as "20-Feb, 10-Mar, 20-Mar, ..."
          //
          // So for day intervals, we use the `step` param of `.range()`
          // instead. That makes it start at the first interval boundary,
          // and then add `step` intervals to it to get each subsequent boundary.
          // So you get e.g. "17-Feb, 27-Feb, 09-Mar, 19-Mar...""
          .every(tickUnit === 'day' ? 1 : tickStep)!
          .range(startDate, endDate, tickUnit === 'day' ? tickStep : undefined)
          .map((d) => d.valueOf());

        const xGridLines =
          gridStep && gridUnit
            ? tzInterval(timeZone, gridUnit)
                .every(gridUnit === 'day' ? 1 : gridStep)!
                .range(
                  startDate,
                  endDate,
                  gridUnit === 'day' ? gridStep : undefined
                )
                .map((d) => d.valueOf())
            : xTicks;
        const xTickFormat =
          labelFormatFn ??
          ((tickDate: Date, index: number) =>
            moment
              .tz(tickDate, timeZone!)
              .format(
                firstLabelFormat && index === 0 ? firstLabelFormat : labelFormat
              ));
        return {
          xTicks,
          xGridLines,
          xTickFormat,
        };
      };

      const plotYears = endMoment.diff(startMoment, 'year', true);

      if (plotYears >= 100) {
        return makeTicks({
          labelFormat: YEAR_ONLY,
          tickStep: 20,
          tickUnit: 'year',
        });
      } else if (plotYears >= 60) {
        return makeTicks({
          labelFormat: YEAR_ONLY,
          tickStep: 10,
          tickUnit: 'year',
          gridStep: 5,
          gridUnit: 'year',
        });
      } else if (plotYears >= 20) {
        return makeTicks({
          labelFormat: YEAR_ONLY,
          tickStep: 5,
          tickUnit: 'year',
          gridStep: 1,
          gridUnit: 'year',
        });
      } else if (plotYears >= 10) {
        return makeTicks({
          labelFormat: YEAR_ONLY,
          tickStep: 2,
          tickUnit: 'year',
          gridStep: 1,
          gridUnit: 'year',
        });
      } else if (plotYears >= 5) {
        return makeTicks({
          labelFormat: YEAR_ONLY,
          tickStep: 1,
          tickUnit: 'year',
        });
      } else if (plotYears >= 2) {
        return makeTicks({
          labelFormat: MONTH_YEAR,
          tickStep: 3,
          tickUnit: 'month',
          gridStep: 1,
          gridUnit: 'month',
        });
      } else if (plotYears >= 1) {
        return makeTicks({
          labelFormat: MONTH_YEAR,
          tickStep: 2,
          tickUnit: 'month',
          gridStep: 1,
          gridUnit: 'month',
        });
      }

      const plotMonths = endMoment.diff(startMoment, 'month', true);

      if (plotMonths >= 6) {
        return makeTicks({
          labelFormat: MONTH_YEAR,
          tickStep: 1,
          tickUnit: 'month',
        });
      } else if (plotMonths >= 2) {
        return makeTicks({
          labelFormat: DAY_MONTH_YEAR,
          tickStep: 10,
          tickUnit: 'day',
          gridStep: 5,
          gridUnit: 'day',
        });
      } else if (plotMonths >= 1) {
        return makeTicks({
          labelFormat: DAY_MONTH_YEAR,
          tickStep: 5,
          tickUnit: 'day',
          gridStep: 1,
          gridUnit: 'day',
        });
      }

      const plotWeeks = endMoment.diff(startMoment, 'week', true);

      if (plotWeeks >= 2) {
        return makeTicks({
          labelFormat: DAY_MONTH_YEAR,
          tickStep: 2,
          tickUnit: 'day',
          gridStep: 1,
          gridUnit: 'day',
        });
      } else if (plotWeeks >= 1) {
        return makeTicks({
          labelFormat: DAY_MONTH_YEAR,
          tickStep: 1,
          tickUnit: 'day',
          gridStep: 12,
          gridUnit: 'hour',
        });
      }

      const plotDays = endMoment.diff(startMoment, 'day', true);

      if (plotDays >= 3) {
        return makeTicks({
          labelFormatFn: DAY_OR_HOUR,
          tickStep: 12,
          tickUnit: 'hour',
          gridStep: 6,
          gridUnit: 'hour',
        });
      } else if (plotDays >= 2) {
        return makeTicks({
          labelFormatFn: DAY_OR_HOUR,
          tickStep: 6,
          tickUnit: 'hour',
          gridStep: 1,
          gridUnit: 'hour',
        });
      } else if (plotDays >= 1) {
        return makeTicks({
          firstLabelFormat: DAY_MONTH_YEAR,
          labelFormat: HOUR_MIN_SEC,
          tickStep: 3,
          tickUnit: 'hour',
          gridStep: 1,
          gridUnit: 'hour',
        });
      }

      return makeTicks({
        firstLabelFormat: DAY_MONTH_YEAR,
        labelFormat: HOUR_MIN_SEC,
        tickStep: 1,
        tickUnit: 'hour',
      });
    }
  );

  // Empty object returned if there are no alarm series to plot.
  const NO_ALARM_SERIES = {
    alarmThresholdLabels: [],
    alarmsForPlotting: [],
  };

  /**
   * Memoized function that examines our data about alarm levels, their values,
   * and their start-stop dates, and calculates the series of x-y points needed
   * to plot the alarms. It returns data to plot each alarm parameter level as
   * a LineSeries and a VerticalRectSeries (to shade in the background), and
   * the labels for all the alarms as a single LabelSeries
   *
   * @param {*} { yMin, yMax }
   * @param {*} { xMin, xMax }
   * @param {*} alarms
   * @returns
   */
  const calculateAlarmPlots = createSelector(
    calculateYDomains,
    calculateXDomain,
    (props: { readingsSeries: any[] }) => props.readingsSeries.length,
    (props: Pick<TimeSeriesPlotOwnProps, 'alarmParamSeries'>) =>
      props.alarmParamSeries,
    (props: Pick<TimeSeriesPlotOwnProps, 'i18n'>) => props.i18n,
    function (yDomains, xDomain, readingsSeriesCount, alarmParamSeries, i18n) {
      // We don't plot the alams if we're plotting multiple readings series
      if (readingsSeriesCount > 1) {
        return NO_ALARM_SERIES;
      }

      // No alarm parameters to plot.
      if (!alarmParamSeries || alarmParamSeries.length === 0) {
        return NO_ALARM_SERIES;
      }

      // Since we know we're only plotting one readings series, find its yDomain
      // (whichever axis it's on)
      const yDomain = yDomains.left || yDomains.right || yDomains.right2;

      // If the domains couldn't be calculated (probably because there are no
      // readings), the alarms can't be plotted.
      if (!yDomain || !xDomain) {
        return NO_ALARM_SERIES;
      }

      const xMin = xDomain[0].toISOString();
      const xMax = xDomain[1].toISOString();
      const [yMin, yMax] = yDomain;

      // The labels for the different alarm threshold lines
      const alarmThresholdLabels: TSPlotAlarmParamLabel[] = [];

      const alarmsForPlotting: TSPlotAlarmParamSeriesWithShapes[] =
        alarmParamSeries.map((alarmType) => {
          // CSS classnames for alarms of this type.
          const alarmLevelClassName = `plot-alarm-level-${alarmType.level.replace(
            ' ',
            '-'
          )}`;
          const alarmTypeClassName = `plot-alarm-type-${alarmType.type.replace(
            ' ',
            '-'
          )}`;
          // Points to draw the shading rectangles
          const shadedAlarmAreas: TSPlotAlarmParamBox[] = [];
          // Points to draw the lines at the top of the rectangles (where the actual
          // threshold is)
          const alarmThresholdLines: TSPlotAlarmParamOutlinePoint[] = [];

          const numThresholds = alarmType.thresholds.length;
          let y0;
          if (alarmType.type === Enum.AlarmParameter_TYPE.minimum) {
            y0 = yMin;
          } else {
            y0 = yMax;
          }

          let isLabelSet = false;

          for (let i = 0; i < numThresholds; i++) {
            const { threshold, start_datetime, end_datetime } =
              alarmType.thresholds[i];
            let y =
              threshold !== null && threshold > yMin && threshold < yMax
                ? threshold
                : null;
            // The alarm level is outside the plotted y domain. Skip it.
            if (y === null) {
              continue;
            }
            let startX =
              start_datetime && start_datetime > xMin ? start_datetime : xMin;
            let endX =
              end_datetime && end_datetime < xMax ? end_datetime : xMax;
            // The alarm period is outside the plotted x domain. Skip it.
            if (!(startX < endX)) {
              continue;
            }

            if (!isLabelSet && y !== null) {
              isLabelSet = true;
              // NOTE: Most styling for the alarm labels is done on the <LabelSeries>
              // component, or on its CSS class. However, x and y padding have to
              // be done here, on each individual label.
              alarmThresholdLabels.push({
                x: new Date(startX),
                y,
                label: i18n._(
                  transEnum('AlarmParameter_LEVEL', alarmType.level)
                ),
                yOffset:
                  // Vertical padding for the label (in pixels).
                  // Positive values move the text down, negative values move it up.
                  // At "0" the text's baseline is along the alarm threshold line.
                  //
                  // Put "min" labels below the line, "max" labels above
                  alarmType.type === Enum.AlarmParameter_TYPE.minimum ? 12 : -5,
                // Horizontal padding (in pixels)
                xOffset: 5,
              });
            }

            // All styling for the background areas is done in their CSS class
            // or on their VerticalRectSeries component.
            shadedAlarmAreas.push({
              x: new Date(endX),
              y,
              x0: new Date(startX),
              y0,
            });

            // All styling for the alarm threshold lines is done in their CSS class
            // or on their LineSeries component.
            alarmThresholdLines.push(
              { x: new Date(startX), y },
              { x: new Date(endX), y },
              { x: new Date(endX), y: null }
            );
          }

          return {
            ...alarmType,
            shadedAlarmAreas,
            alarmThresholdLines,
            alarmTypeClassName,
            alarmLevelClassName,
          };
        });
      return { alarmThresholdLabels, alarmsForPlotting };
    }
  );
  return {
    calculateXDomain,
    selectSeriesByAxis,
    calculateYDomains,
    calculateAxisDomains,
    calculateXTicks,
    calculateAlarmPlots,
    calculateSeriesLabels,
  };
}

/**
 * Calculate plot points to draw the line for marking the periods of time where
 * the observation point for a data series, had an instrument reliability set
 * to "U - Unreliable"
 */
export function calculateUnreliablePeriodsForSerie(
  readingsSeries: PlotReadingsSeries<YAxisSide>
): { x: Date; y: number | null }[] {
  const { observationPoint, readings } = readingsSeries;
  if (!readings || readings.length <= 1) {
    return [];
  }

  const xMin = readings[0].x.valueOf();
  const xMax = readings[readings.length - 1].x.valueOf();

  const unreliablePeriods = sortBy(
    observationPoint.observation_point_time_dependent_fields
      .instrument_reliability,
    (r) => r.start_datetime
  )
    .map((r, rIdx, all) => {
      // TODO: Enum for the reliability values?
      if (r.value === 'U' || r.value === 'S') {
        const nextPeriod = all[rIdx + 1];
        return {
          start: new Date(r.start_datetime).valueOf(),
          end: nextPeriod
            ? new Date(nextPeriod.start_datetime).valueOf()
            : Infinity,
        };
      } else {
        return null;
      }
    })
    .filter(isNotNull);

  const { readings: unreliableReadings } = unreliablePeriods.reduce(
    (acc, { start, end }) => {
      // Nothing to draw if this period is entirely outside of the plot.
      if (end < xMin || start > xMax) {
        return acc;
      }

      const idxAfterStart =
        start < xMin
          ? 0
          : findIndex(readings, (r) => r.x.valueOf() >= start, acc.searchFrom);

      if (idxAfterStart === -1) {
        return acc;
      }

      const idxAfterEnd =
        end > xMax
          ? readings.length - 1
          : findIndex(readings, (r) => r.x.valueOf() > end, idxAfterStart);

      // Calculate interpolated Y values for the start and end
      // dates of the unreliable period, so that we can draw the dashed line
      // up to the start/end date even if it's between readings.
      const [startY, endY] = [
        [start, idxAfterStart],
        [end, idxAfterEnd],
      ].map(([time, idxAfterTime]) => {
        const before = readings[idxAfterTime - 1];
        const after = readings[idxAfterTime];

        // If the date is during a gap in the plot, or outside the edge of the
        // plot, there's no line for us to draw over, so we need no interpolated
        // value.
        if (!before || before.y === null || !after || after.y === null) {
          return null;
        }

        return scaleLinear()
          .domain([before.x.valueOf(), after.x.valueOf()])
          .range([before.y, after.y])(time);
      });

      return {
        searchFrom: idxAfterEnd,
        readings: acc.readings.concat(
          // Line from the period's start date (between readings) to the first
          // reading in the period.
          startY === null ? [] : [{ x: new Date(start), y: startY }],

          // All the readings within the period.
          readings.slice(idxAfterStart, idxAfterEnd),

          // Line from the last reading in the period, to the period's end
          // date (between readings)
          endY === null ? [] : [{ x: new Date(end), y: endY }],

          // A null reading to make a gap between this and
          // the next unreliable period.
          [{ x: new Date(end), y: null }]
        ),
      };
    },
    {
      searchFrom: 0,
      readings: [] as { x: Date; y: number | null }[],
    }
  );
  return unreliableReadings;
}

/**
 * 90% copied from react-vis's implementation
 * https://github.com/uber/react-vis/blob/master/src/utils/axis-utils.js#L431
 *
 * Added our improvement when the size is less than 300, rather than return 5,
 * we calcualte a sensible number base on the AXIS_LABEL_FONT_HEIGHT
 */
export function getTicksTotalFromSize(size: number) {
  if (size > 700) {
    return 20;
  }
  if (size > 300) {
    return 10;
  }
  if (size > 150) {
    return 5;
  }
  return 2;
}

export interface TSPlotDomainProps {
  xDomain: [Date, Date];
  yDomain: [number, number];
}

export function selectMouseoverPoints(
  defaultDomainProps: TSPlotDomainProps | null,
  readingsSeries: PlotReadingsSeries<YAxisSide>[],
  yScaleFromDefaultTo: TSPlotYAxisScales,
  colorsPool?: string[],
  markersPool?: string[]
): TSPlotMouseoverPoint[] {
  if (!defaultDomainProps) {
    return [];
  }

  const allSeriesReadings: (TSPlotMouseoverPoint | null)[] = [];
  for (let seriesIdx = 0; seriesIdx < readingsSeries.length; seriesIdx++) {
    const serie = readingsSeries[seriesIdx];

    // Mouseover for rainfall histograms needs to be handled differently.
    if (serie.observationPoint.histogram_day_cutoff_time) {
      continue;
    }

    if (!serie.readings) {
      continue;
    }

    const seriesColor = getColorForIdx(seriesIdx, colorsPool);
    const marker = getMarkerForIdx(seriesIdx, markersPool);

    const yAxisSide = serie.settings.axis_side;
    // We'll need to scale the value from its own axis's domain, to the
    // "default" y axis domain (if any). (The `.invert()` is a standard
    // part of a d3 scale function, which runs the conversion in the
    // opposite direction, so in this case, from our scale to default.)
    const toDefaultScale = yScaleFromDefaultTo[yAxisSide].invert;
    for (let readingIdx = 0; readingIdx < serie.readings.length; readingIdx++) {
      const dataPoint = serie.readings[readingIdx];
      if (dataPoint.y === null) {
        allSeriesReadings.push(null);
        continue;
      }

      // All the reading values need to be converted to the same `defaultDomain`
      const scaledValue = toDefaultScale(dataPoint.y);

      // Filter out points that lie outside the displayed area of the graph.
      if (
        defaultDomainProps &&
        scaledValue >= defaultDomainProps.yDomain[0] &&
        scaledValue <= defaultDomainProps.yDomain[1] &&
        dataPoint.x >= defaultDomainProps.xDomain[0] &&
        dataPoint.x <= defaultDomainProps.xDomain[1]
      ) {
        allSeriesReadings.push({
          ...dataPoint,
          y: scaledValue,
          originalValue: dataPoint.y,
          obsPointCode: serie.observationPoint.code,
          seriesColor,
          marker,
          itemNumber: serie.settings.item_number,
          unit: serie.observationPoint.instrument_type.standard_units,
          showAnalysisComments: serie.settings.show_analysis_comment_indicators,
          showInspectorComments:
            serie.settings.show_inspector_comment_indicators,
        });
      }
    }
  }

  return allSeriesReadings.filter(isNotNull);
}

export function calculatePortRLPlotLines(
  readingsSeries: PlotReadingsSeries<YAxisSide>[],
  axisDomains: TSPlotDomains | undefined,
  defaultDomainProps: TSPlotDomainProps | null,
  yScaleFromDefaultTo: TSPlotYAxisScales,
  labelsPool: string | undefined,
  colorsPool: string[] | undefined
) {
  if (!defaultDomainProps || !axisDomains) {
    return [];
  }

  return orderBy(
    readingsSeries
      .map((serie, idx) => {
        // No line to draw if the plot has not enabled "show plot port rl",
        // or if the obs point has no port RL data
        if (
          !serie.settings.show_plot_port_rl ||
          serie.observationPoint.port_rl === null
        ) {
          return null;
        }

        // No line to draw if for some weird reason the domain for the serie's
        // Y axis has not been computed.
        const yDomain = axisDomains[serie.settings.axis_side];
        if (!yDomain) {
          return null;
        }

        const portRL = serie.observationPoint.port_rl;
        const [yMin, yMax] = yDomain;

        // Don't render port RLs that fall outside the plot
        if (portRL < yMin || portRL > yMax) {
          return null;
        }

        return {
          // X position: Left edge of the graph.
          x: defaultDomainProps.xDomain[0],
          // Y position: Port RL value
          // Since we're plotting left and right axis port RLs in
          // one series, we need to scale them all to the same Y
          // scale.
          // (The `.invert()` is a standard part of a d3 scale
          // function, which runs the conversion in the opposite
          // direction, so in this case, from our scale to default.)
          y: yScaleFromDefaultTo[serie.settings.axis_side].invert(
            serie.observationPoint.port_rl
          ),
          port_rl: serie.observationPoint.port_rl,
          color: getColorForIdx(idx, colorsPool),
          label: getLabelForIdx(idx, labelsPool),
        };
      })
      .filter(isNotNull),
    // Sort them in descending order by value, so that "lower" ones will write over "higher" ones like in the wireframe
    'value',
    'desc'
  );
}

export function clampNumeric(
  numericValue: number,
  { min, max }: { min?: number; max?: number }
): number {
  if (min !== undefined && numericValue < min) {
    return min;
  }
  if (max !== undefined && numericValue > max) {
    return max;
  }
  return numericValue;
}

function clampDate(
  dateString: string | Date,
  { min, max }: { min?: Date; max?: Date }
): Date {
  const momentValue = moment(dateString);
  if (min !== undefined && momentValue.isBefore(min)) {
    return min;
  }
  if (max !== undefined && momentValue.isAfter(max)) {
    return max;
  }
  return momentValue.toDate();
}

export function calculateAnnotationPlotLines(
  defaultDomainProps: TSPlotDomainProps | null,
  annotations: StoredPlotAnnotation[] | undefined
) {
  if (!defaultDomainProps || !annotations || !annotations.length) {
    return null;
  }

  const [xMin, xMax] = defaultDomainProps.xDomain;
  const [yMin, yMax] = defaultDomainProps.yDomain;

  const yAnnotations = annotations.filter(
    (annot) => annot.axis === Enum.StoredPlotAnnotationAxis.Y
  ) as StoredPlotNumericAnnotation[];

  const xAnnotations = annotations.filter(
    (annot) => annot.axis === Enum.StoredPlotAnnotationAxis.X
  ) as StoredPlotDatetimeAnnotation[];

  // Sort them in descending order by value so that ones lower in the plot
  // will layer on top of ones higher in the plot.
  const sortedHorizontalAnnotations = orderBy(
    yAnnotations,
    (item) => Number(item.numeric_value),
    'desc'
  )
    .map(({ numeric_value, start_datetime, end_datetime, ...annot }) => ({
      ...annot,
      numericValue: Number(numeric_value),
      startDatetime: clampDate(start_datetime ?? xMin, { min: xMin }),
      endDatetime: clampDate(end_datetime ?? xMax, { max: xMax }),
    }))
    .filter(
      // Don't render annotations that fall outside the plot.
      ({ numericValue, startDatetime, endDatetime }) =>
        !(
          numericValue < yMin ||
          numericValue > yMax ||
          moment(startDatetime).isAfter(xMax) ||
          moment(endDatetime).isBefore(xMin)
        )
    );

  const sortedVerticalAnnotations = orderBy(
    xAnnotations,
    (item) => new Date(item.datetime_value).valueOf(),
    'desc'
  )
    .map(({ datetime_value, start_numeric, end_numeric, ...annot }) => ({
      ...annot,
      datetimeValue: new Date(datetime_value),
      startNumeric: clampNumeric(
        start_numeric === null ? yMin : Number(start_numeric),
        { min: yMin }
      ),
      endNumeric: clampNumeric(
        end_numeric === null ? yMax : Number(end_numeric),
        { max: yMax }
      ),
    }))
    .filter(
      // Don't render annotations that fall outside the plot.
      ({ datetimeValue, startNumeric, endNumeric }) =>
        !(
          moment(datetimeValue).isBefore(xMin) ||
          moment(datetimeValue).isAfter(xMax) ||
          startNumeric > yMax ||
          endNumeric < yMin
        )
    );

  const horizontalLines = sortedHorizontalAnnotations
    .filter(
      (annot) => annot.line_style === Enum.StoredPlotAnnotation_LINE_STYLE.FULL
    )
    .flatMap((annot) => [
      // A horizontal line with the annotation's value as its vertical position,
      { x: annot.startDatetime, y: annot.numericValue },
      { x: annot.endDatetime, y: annot.numericValue },
      // A null to prevent a line from being drawn attaching this line to the next
      // annotation line. (Since we render them all as one `<LineSeries>` component,
      // which in turn renders to a single SVG `<path>` element)
      { x: null, y: null },
    ]);

  const horizontalLabels = sortedHorizontalAnnotations
    .filter((annot) => annot.label)
    .map((annot) => ({
      x:
        annot.stub_position === StoredPlotAnnotation_STUB_POSITION.RIGHT
          ? xMax
          : annot.startDatetime,
      y: annot.numericValue,
      label: annot.label,
      position: annot.position || StoredPlotAnnotationLabelPosition.ABOVE,
      stubPosition: annot.stub_position,
    }));

  const horizontalStubs = sortedHorizontalAnnotations
    .filter(
      (annot) => annot.line_style === Enum.StoredPlotAnnotation_LINE_STYLE.STUB
    )
    .map((annot) => ({
      x:
        annot.stub_position === Enum.StoredPlotAnnotation_STUB_POSITION.LEFT
          ? xMin
          : xMax,
      y: annot.numericValue,
      stubPosition: annot.stub_position,
    }));

  const verticalLines = sortedVerticalAnnotations
    .filter(
      (annot) => annot.line_style === Enum.StoredPlotAnnotation_LINE_STYLE.FULL
    )
    .flatMap((annot) => [
      { x: annot.datetimeValue, y: annot.startNumeric },
      { x: annot.datetimeValue, y: annot.endNumeric },
      { x: null, y: null },
    ]);

  const verticalLabels = sortedVerticalAnnotations
    .filter((annot) => annot.label)
    .map((annot) => ({
      x: annot.datetimeValue,
      y:
        annot.stub_position === StoredPlotAnnotation_STUB_POSITION.TOP
          ? yMax
          : annot.startNumeric,
      label: annot.label,
      position: annot.position || StoredPlotAnnotationLabelPosition.LEFT,
      stubPosition: annot.stub_position,
    }));

  const verticalStubs = sortedVerticalAnnotations
    .filter(
      (annot) => annot.line_style === Enum.StoredPlotAnnotation_LINE_STYLE.STUB
    )
    .map((annot) => ({
      x: annot.datetimeValue,
      y:
        annot.stub_position === Enum.StoredPlotAnnotation_STUB_POSITION.TOP
          ? yMax
          : yMin,
      stubPosition: annot.stub_position,
    }));

  return {
    horizontalLines: horizontalLines.length > 0 ? horizontalLines : null,
    horizontalLabels: horizontalLabels.length > 0 ? horizontalLabels : null,
    horizontalStubs: horizontalStubs.length > 0 ? horizontalStubs : null,
    verticalLines: verticalLines.length > 0 ? verticalLines : null,
    verticalLabels: verticalLabels.length > 0 ? verticalLabels : null,
    verticalStubs: verticalStubs.length > 0 ? verticalStubs : null,
  };
}

interface SerieLabelPosition {
  x: Date;
  y: number;
  side: 'left' | 'right';
}

/**
 * Generate the expected quickplot URL that would lead to a particular set of
 * resolved settings.
 *
 * TODO: de-dupe very similar logic in `formValuesToUrl()` in
 * `QuickPlotSettings`
 * @param resolved
 */
export function timeSeriesMetadataToUrl(
  resolved: TimeSeriesPlotSettings | null
) {
  if (!resolved) {
    return {
      path: '',
      search: new URLSearchParams(),
    };
  }
  const { storedPlot } = resolved;

  const identifierString = resolved.reading_series
    .map((rs, idx) =>
      getObservationPointItemUrlCode(
        resolved.observationPoints[idx] && resolved.observationPoints[idx].code,
        rs.item_number
      )
    )
    .join(',');

  const params = new URLSearchParams();
  params.set(
    'yAxis',
    resolved.reading_series
      .map(({ axis_side: y_axis_side }) =>
        y_axis_side === 'right' ? 'R' : 'L'
      )
      .join('')
  );

  params.set(
    'yAxes',
    resolved.axes
      .map(({ side, minimum, maximum }) => {
        if (minimum === null || maximum === null) {
          return null;
        }
        return [side === 'right' ? 'R' : 'L', minimum, maximum].join('~');
      })
      .filter(isNotNull)
      .join('_')
  );

  const numberOfMonths = parseIntervalFromString(
    resolved.duration,
    'months'
  ).intervalNumber;
  if (numberOfMonths !== null) {
    params.set('numberOfMonths', String(numberOfMonths));
  }

  if (resolved.start_datetime) {
    params.set('startDatetime', resolved.start_datetime);
  }

  if (resolved.end_datetime) {
    params.set('endDatetime', resolved.end_datetime);
  }

  if (storedPlot) {
    params.set('storedPlot', storedPlot.name);
  }

  if (resolved.showAnalysisComments) {
    params.set('showAnalysisComments', '1');
  }

  return {
    path: identifierString,
    search: params,
  };
}

export function getTimeZoneFromPlotMetadata(
  resolved: Pick<PlotSettings, 'storedPlot' | 'observationPoints'> | null
): string | null {
  if (!resolved) {
    return null;
  } else if (resolved.storedPlot) {
    return resolved.storedPlot.area.time_zone.name;
  } else if (
    resolved.observationPoints &&
    resolved.observationPoints.length > 0
  ) {
    return resolved.observationPoints[0].time_zone.name;
  } else {
    return null;
  }
}

/**
 * TODO: Duplication of the business logic from `getTimeZoneFromResolvedSettings`
 *
 * @param values
 * @param resolvedSettings
 */
export function getTimeZoneFromPlotSettingsForm(
  values: QuickPlotSettingsFormValue,
  storedPlot: StoredTimeSeriesPlotWithArea | null
): string | null {
  if (storedPlot) {
    return storedPlot.area.time_zone.name;
  } else if (values.opItemDetails.length > 0) {
    return getSafely(
      () => values.opItemDetails[0].observationPoint!.time_zone.name,
      null
    );
  } else {
    return null;
  }
}

/**
 * Make an identifier for a readings series, for use in the URL
 * The format is the the observation point code, followed by a hyphen,
 * followed by the instrument item number.
 *
 * If the instrument item number is 1, it can be ommitted and the observation
 * point code used by itself.
 *
 * @param observationPointCode
 * @param item_number
 */
export function getObservationPointItemUrlCode(
  observationPointCode: string,
  item_number: number
) {
  if (item_number === 1) {
    return observationPointCode;
  } else {
    return `${observationPointCode}-${item_number}`;
  }
}

/**
 * Get the obs point code and instrument item number from a readings series
 * code generated by `getReadingSeriesUrlCode()`. Returns `null` if the string
 * is not a valid reading series URL code.
 *
 * @param identifierStr
 */
export function splitObservationPointItemUrlCode(
  identifierStr: string
): ObservationPointItemUrlCode | null {
  const matches = RS_URL_CODE_REGEX.exec(identifierStr) || [];
  if (!matches || !matches[1]) {
    return null;
  }

  return {
    observationPointCode: matches[1],
    itemNumber: matches[2] ? Number.parseInt(matches[2]) : 1,
  };
}
// TODO: Use underscore instead of hyphen?
const RS_URL_CODE_REGEX = /^(.*?)(?:-([0-9]+))?$/;
