import React, { useEffect } from 'react';
import { CumulativePlotView } from './CumulativePlotView';
import { createStructuredSelector } from 'reselect';
import { RouteComponentProps } from 'react-router-dom';
import {
  fetchCumulativePlotData,
  selectCumulativePlotData,
  unmountCumulativePlot,
} from 'ducks/plot/cumulative';
import { FullState } from 'main/reducers';
import { useDispatch, useSelector } from 'react-redux';

interface CumulativePlotSettingsFromUrl {
  observationPointCode: string;
  itemNumber: number;
  startYear: number;
  endYear: number;
}

interface UrlMatchProps {
  op?: string;
  item?: string;
  start?: string;
  end?: string;
}

type OwnProps = RouteComponentProps<UrlMatchProps>;

export const getCumulativePlotSettingsFromUrl = createStructuredSelector<
  OwnProps,
  CumulativePlotSettingsFromUrl
>({
  observationPointCode: (props) => props.match.params.op || '',
  itemNumber: (props) => +(props.match.params.item || 0),
  startYear: (props) => +(props.match.params.start || 0),
  endYear: (props) => +(props.match.params.end || 0),
});

export function CumulativePlotScreen(props: OwnProps) {
  const settingsFromUrl = getCumulativePlotSettingsFromUrl(props);

  const { observationPointCode, itemNumber, startYear, endYear } =
    settingsFromUrl;

  const dispatch = useDispatch();

  const plotState = useSelector((state: FullState) =>
    selectCumulativePlotData(state, 0)
  );

  useEffect(
    () => () => {
      dispatch(unmountCumulativePlot());
    },
    [dispatch]
  );

  useEffect(() => {
    if (!observationPointCode || !itemNumber || !startYear || !endYear) {
      return;
    }
    dispatch(
      fetchCumulativePlotData(
        observationPointCode,
        itemNumber,
        startYear,
        endYear
      )
    );
  }, [dispatch, observationPointCode, itemNumber, startYear, endYear]);

  const errorMessage = plotState?.errorMessage ?? null;
  const isLoading = plotState?.isLoading ?? false;

  const observationPoint = plotState?.observationPoints?.[0] ?? null;
  const plotReadings = plotState?.plotReadings ?? null;

  return (
    <CumulativePlotView
      observationPoint={observationPoint}
      cumulativePlotReadings={plotReadings}
      isLoading={isLoading}
      errorMessage={errorMessage}
      itemNumber={itemNumber}
      startYear={startYear}
      endYear={endYear}
    />
  );
}
